import * as React from 'react'
import Box from '@mui/material/Box'
import './components/index.css'
import Home from './pages/Home'
import LandingPage from './pages/LandingPage'

export default function App () {
  return (
    <div>
      <Home  />
        <Box position={'absolute'} top={0} zIndex={-10}>
        <LandingPage />
        </Box>

   
    </div>
  )
}
