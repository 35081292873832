import * as React from 'react'
import NavBar from '../../components/Navbar'
import {
   BreadText, ScrollBarDiv, IndexBox,GlobalStyle, BackgroundBox,SectionTitle, MetaTitle, DefaultBox
} from '../../styled/Utils'
import { Box } from '@mui/system'
import Cover from '../images/projects/Lysistrata/Sweater.jpg'
import Poster from '../images/projects/Lysistrata/Poster.jpg'
import Outline from '../images/projects/Lysistrata/Outline.jpg'

const Index = () => {
  return (
    <ScrollBarDiv>
    <BackgroundBox>
      <GlobalStyle/>
      <NavBar />
      <IndexBox>
        
      <DefaultBox>
      <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={8} //! Se på denne
    >
      <Box
        component="img"
        maxWidth="100%"
        alt="Speaker Cover Photo"
        src={Cover}

      />


      <Box width={'100%'}> 

  
      <MetaTitle>Sweater design and poster for theatre production</MetaTitle>
      <SectionTitle> Lysistrata </SectionTitle>
      <BreadText>
        Design for a poster and a sweater for a theatre production at Romerike
      </BreadText>

      <Box display="flex" justifyContent="space-between" paddingTop={3}>
        <Box>
          <BreadText bold> Context </BreadText>
          <BreadText>
            Product Design <br/>
            Hobby Project
          </BreadText>
        </Box>
  
        <Box>
          <BreadText bold> Team </BreadText>
          <BreadText>
            Me <br/>
            David Kløve Kjernlie
          </BreadText>
        </Box>
  
        <Box>
          <BreadText bold> Tools & Keywords </BreadText>
          <BreadText>
            Photoshop
            <br />
            Illustrator
          </BreadText>
        </Box>
  
        
  
      </Box>
      </Box>

      <Box display={'flex'}>
   
      <Box
        component="img"
        maxWidth={'40vw'}
        alt="Speaker Cover Photo"
        src={Poster}

      />
        <Box
        component="img"
        maxWidth={'40vw'}
        alt="Speaker Cover Photo"
        src={Outline}

      />
        
        </Box>

      </Box>



      </DefaultBox>

      </IndexBox>
    </BackgroundBox>
</ScrollBarDiv>

  )
}

export default Index
