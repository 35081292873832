import React, {useEffect} from 'react'
import {
  GlobalStyle,
  DefaultBox,
  IndexBox,
  BackgroundBox,
  ScrollBarDiv
} from '../../styled/Utils'
import DesignSystemContent from './DesignSystemContent'
import NavBar from '../../components/Navbar'


export default function DesignSystem () {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <ScrollBarDiv>
    <BackgroundBox>
      <NavBar />
      <IndexBox>

          <GlobalStyle/>        
          <DesignSystemContent />

      </IndexBox>
    </BackgroundBox>
  </ScrollBarDiv>

  
  )
}
