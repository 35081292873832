import * as React from 'react'
import NavBar from '../../components/Navbar'
import {
   BreadText, ScrollBarDiv, IndexBox,GlobalStyle, BackgroundBox,SectionTitle, MetaTitle, DefaultBox
} from '../../styled/Utils'
import { Box, Container } from '@mui/system'
import Cover from '../images/projects/Knagg/Barneknagg.jpg'
import CloseUp from '../images/projects/Knagg/CloseUp.jpg'
import Packaging from '../images/projects/Knagg/Packaging.jpg'
import Snap from '../images/projects/Knagg/Snap.jpg'
import Whole from '../images/projects/Knagg/Whole.jpg'


function MetaInfo() {
    return (
      <Box display="flex" justifyContent="space-between" mt={3}>
        <Box>
          <BreadText bold> Context </BreadText>
          <BreadText>
            Product Design <br/>
            Packaging Design
          </BreadText>
        </Box>
  
        <Box>
          <BreadText bold> Team </BreadText>
          <BreadText>
            Individual
          </BreadText>
        </Box>
  
        <Box>
          <BreadText bold> Tools & Keywords </BreadText>
          <BreadText>
            Adobe Illustrator
            <br />
            Keyshot 
            <br />
            Fusion 360 
            
          </BreadText>
        </Box>
  
        
  
      </Box>
    );
  }
  


const Index = () => {
  return (
    <ScrollBarDiv>
    <BackgroundBox>
      <GlobalStyle/>
      <NavBar />
      <IndexBox>
        
      <DefaultBox>
      <Container sx={{'display':'flex', 'flexDirection': 'column', gap:6 }}>

      <Box
        component="img"
        maxWidth="100%"
        alt="Speaker Cover Photo"
        src={Cover}
        pb={6}
      />


      <Box width={'100%'} pb={6} > 
      <MetaTitle>A childrens clothes hook with packaging</MetaTitle>
      <SectionTitle> SUB </SectionTitle>
      <BreadText>
     The SUB is a childrens clothes hanger inspired by The Beatles.
      This was done in a NTNU Course to learn about packaging design 
      and product design
      </BreadText>

      <MetaInfo/>
      </Box>
      <Box
        component="img"
        maxWidth="100%"
        alt="Speaker Cover Photo"
        src={CloseUp}
      />
      <Box
        component="img"
        maxWidth="100%"
        alt="Speaker Cover Photo"
        src={Snap}
      />
      <Box
        component="img"
        maxWidth="100%"
        alt="Speaker Cover Photo"
        src={Whole}
      />

      <Box
        component="img"
        maxWidth="100%"
        alt="Speaker Cover Photo"
        src={Packaging}
      />
  

    </Container>




      </DefaultBox>

      </IndexBox>
    </BackgroundBox>
</ScrollBarDiv>

  )
}

export default Index
