import * as React from 'react'
import NavBar from '../../components/Navbar'
import {
   BreadText, ScrollBarDiv, IndexBox,GlobalStyle, BackgroundBox, WorkSummaryTitle, MetaTitle, Tags, DefaultBox
} from '../../styled/Utils'
import { Box } from '@mui/system'

import LCP_Side_01 from '../images/projects/LCP/LCP_Side_01.jpg'
import LCP_Side_02 from '../images/projects/LCP/LCP_Side_02.jpg'
import LCP_Side_03 from '../images/projects/LCP/LCP_Side_03.jpg'
import LCP_Side_04 from '../images/projects/LCP/LCP_Side_04.jpg'
import LCP_Side_05 from '../images/projects/LCP/LCP_Side_05.jpg'
import LCP_Side_06 from '../images/projects/LCP/LCP_Side_06.jpg'
import LCP_Side_07 from '../images/projects/LCP/LCP_Side_07.jpg'
import LCP_Side_08 from '../images/projects/LCP/LCP_Side_08.jpg'
import LCP_Side_11 from '../images/projects/LCP/LCP_Side_11.jpg'
import LCP_Side_12 from '../images/projects/LCP/LCP_Side_12.jpg'
import LCP_Side_13 from '../images/projects/LCP/LCP_Side_13.jpg'
import LCP_Side_14 from '../images/projects/LCP/LCP_Side_14.jpg'
import LCP_Side_15 from '../images/projects/LCP/LCP_Side_15.jpg'
import LCP_Side_16 from '../images/projects/LCP/LCP_Side_16.jpg'
import LCP_Side_17 from '../images/projects/LCP/LCP_Side_17.jpg'
import LCP_Side_18 from '../images/projects/LCP/LCP_Side_18.jpg'
import LCP_Side_19 from '../images/projects/LCP/LCP_Side_19.jpg'
import LCP_Side_20 from '../images/projects/LCP/LCP_Side_20.jpg'
import LCP_Side_21 from '../images/projects/LCP/LCP_Side_21.jpg'
import LCP_Side_22 from '../images/projects/LCP/LCP_Side_22.jpg'
import LCP_Side_23 from '../images/projects/LCP/LCP_Side_23.jpg'
import LCP_Side_24 from '../images/projects/LCP/LCP_Side_24.jpg'
import LCP_Side_25 from '../images/projects/LCP/LCP_Side_25.jpg'
import LCP_Side_26 from '../images/projects/LCP/LCP_Side_26.jpg'
import LCP_Side_27 from '../images/projects/LCP/LCP_Side_27.jpg'
import LCP_Side_28 from '../images/projects/LCP/LCP_Side_28.jpg'
import LCP_Side_29 from '../images/projects/LCP/LCP_Side_29.jpg'
import LCP_Side_30 from '../images/projects/LCP/LCP_Side_30.jpg'
import LCP_Side_31 from '../images/projects/LCP/LCP_Side_31.jpg'
import LCP_Side_32 from '../images/projects/LCP/LCP_Side_32.jpg'
import LCP_Side_33 from '../images/projects/LCP/LCP_Side_33.jpg'
import LCP_Side_34 from '../images/projects/LCP/LCP_Side_34.jpg'
import LCP_Side_37 from '../images/projects/LCP/LCP_Side_37.jpg'


const LCP_Pictures = [LCP_Side_01,
  LCP_Side_02,
  LCP_Side_03,
  LCP_Side_04,
  LCP_Side_05,
  LCP_Side_06,
  LCP_Side_07,
  LCP_Side_08,
  LCP_Side_11,
  LCP_Side_12,
  LCP_Side_13,
  LCP_Side_14,
  LCP_Side_15,
  LCP_Side_16,
  LCP_Side_17,
  LCP_Side_18,
  LCP_Side_19,
  LCP_Side_20,
  LCP_Side_21,
  LCP_Side_22,
  LCP_Side_23,
  LCP_Side_24,
  LCP_Side_25,
  LCP_Side_26,
  LCP_Side_27,
  LCP_Side_28,
  LCP_Side_29,
  LCP_Side_30,
  LCP_Side_31,
  LCP_Side_32,
  LCP_Side_33,
  LCP_Side_34,
  LCP_Side_37]

import styled from 'styled-components'

const LCPImages = styled(Box)`
    width:45vw;
    object-fit:contain;
    & > img{
      height: inherit;
    }
    @media (max-width: 768px) {
    height: inherit;
    width: 100vw;

    }
`;

const Index = () => {
  return (
    <ScrollBarDiv>
    <BackgroundBox>
      <GlobalStyle/>
      <NavBar />
      <IndexBox>
        
      <Box display={'flex'} flexWrap={'wrap'} flexDirection={'row'} justifyContent={'center'} >
          {LCP_Pictures.map((picture,index) => {
            return(
            <LCPImages 
            key={index}
            component='img'
            src={picture}
            />
            )
          })}
      </Box>
      </IndexBox>
    </BackgroundBox>
</ScrollBarDiv>

  )
}

export default Index
