import React from 'react'
import { Box } from '@mui/system'
import styled from 'styled-components'
import { createTheme } from '@mui/material/styles';
import {
  Learnings, SectionHighlight, DefaultBox, MetaTitle, SectionTitle, BreadText,
} from '../../styled/Utils'
import OldDesign from '../images/pepppsy/OldNoteComment.png'
import NewDesign from  '../images/pepppsy/NewComment.png'

const SIGNAL_COLOR = '#C03423';
const HIGHLIGHT_COLOR = '#FFE8E8';

const FunctionalityTitle = styled(MetaTitle)`
  margin-bottom: 20px ;
`;

const theme = createTheme({
  main: {
    palette: {
      primary: SIGNAL_COLOR,
      secondary: HIGHLIGHT_COLOR,
    },
  },
});



function WorkDone(){

  return(
    <DefaultBox>
    <MetaTitle color={SIGNAL_COLOR}> Backlog </MetaTitle>
    <SectionTitle> What functionality did we implement?</SectionTitle>

    <Box display={'flex'} flexDirection={'column'} gap={4}>


    <Box>


    <FunctionalityTitle color={SIGNAL_COLOR}> Administrator functionality:
    Possibility to create more clinic and export statistics </FunctionalityTitle>
    <BreadText>
    As this prototype was made for testing the PM told us that they 
    wanted the possibility to test it on other clinics. 
    Furthermore, the PM wanted to able to export statistics on how the user
    were interacting with the site.
    </BreadText>
    </Box>
    <Box>


    <FunctionalityTitle color={SIGNAL_COLOR}> Comment functionality </FunctionalityTitle>
    <BreadText>
      A big part of the project was to give the ability to the users 
      to communicate back to the health personell if there was
      something they misunderstood or want to clarify during the sessions
    </BreadText>
    </Box>
    <Box>

    <FunctionalityTitle color={SIGNAL_COLOR}> Nofications system for new notes or comments </FunctionalityTitle>
    <BreadText>
      A way to stay up to date
    </BreadText>
    </Box>
    <Box>

    <FunctionalityTitle color={SIGNAL_COLOR}> Internationalization </FunctionalityTitle>
    <BreadText>
      Initially, the website was only in german, but in order to get
      new users and pitch it to other institutions it would need
      to be in english as well
    </BreadText>
    </Box>
    </Box>
  </DefaultBox>
  )
}

const Workflow = () => {

  return(
    <SectionHighlight color={HIGHLIGHT_COLOR}>
      <DefaultBox>
        <MetaTitle color={SIGNAL_COLOR}> Workflow </MetaTitle>
        <SectionTitle> Further development of the prototype</SectionTitle>
        <BreadText>
        We received the project as a functioning MVP, along with a <b>backlog</b> of
        issues handed to us by the project creator. With this as our basis
        we did an interview with our client, a head nurse from the Brandenburg
        Medical School. She served as our client with needs and it
        was our job to formulate this to issues we could solve.
        <br/><br/>
        In order to ensure a deliverable product with consistent
        work with decided on using the Scrum framework.

        </BreadText>

      </DefaultBox>
    </SectionHighlight>
  )
}

const Research = () => {
  return(
   <DefaultBox>
    <MetaTitle color={SIGNAL_COLOR}> Research </MetaTitle>
    <SectionTitle> How to gather information when we can't interview the end-users? </SectionTitle>
        <BreadText>
        One group of end-users were the patients of the clinic. 
        This meant that we had no way to conduct user interviews, 
        this resulted in more interviews being done on the stakeholders we could. We interviewed the client, 
        a nurse student employed at the clinic as well as  Head of Department at the Immanuel Klinik Rüdersdorf.
        <br/> <br/>
        We neeed to find out who the patients were,
        how their day looked like and how would they be expected to use the prototype
        </BreadText>
   </DefaultBox>
      
  )
}


const Redesign = () => {
  return (

    <>
    <SectionHighlight color={HIGHLIGHT_COLOR}>

    <DefaultBox>


      <MetaTitle color={SIGNAL_COLOR}> Redesign </MetaTitle>
      <SectionTitle> Redesign using the existing visual idenitity </SectionTitle>
      <BreadText>
      Work was done by me and another design student to improve the UX for the app. 
      <br/>
      This is an example of the redesign of the note layout as well as the newly developed comment functionality
      <br/>
      The idea was to use the existing design to convey more information. 
      </BreadText>
      </DefaultBox>
      </SectionHighlight>


      <DefaultBox>


    <Box pt={6}>
    <Box    
          
            component='img'
            maxWidth='100%'
            alt='Old Design'
            src={OldDesign}
            style={{ marginBottom: '6rem' }}
            borderRadius='16px'
          />


    <Box
            component='img'
            maxWidth='100%'
            alt='New Design'
            src={NewDesign}
            style={{ marginBottom: '6rem' }}
            borderRadius='16px'
          />
        </Box>
        </DefaultBox>

    </>
  )
}

const MyRole = () => {
  return (
    <DefaultBox>
      <SectionTitle>
        My Role
      </SectionTitle>
      <BreadText>
      The team consisted of 7 informatics students. We all coded, 
      but since I had an interest and background as a designer I took on that responsibility as well. 
      I participated and conducted user interviews and stakeholder interviews as 
      well as creating new design for some of the pages.
      </BreadText>
    </DefaultBox>
  )
}

function MetaInfo() {
  return (
    <Box display="flex" justifyContent="space-between" mt={6}>
      <Box>
        <BreadText bold> Role </BreadText>
        <BreadText>
          Developer
          <br />
          Designer
        </BreadText>
      </Box>

      <Box>
        <BreadText bold> Team </BreadText>
        <BreadText>
          7 informatics 
          students
        </BreadText>
      </Box>

      <Box>
        <BreadText bold> Tools & Keywords </BreadText>
        <BreadText>
        Figma <br/>
        Node.js, Next.js, <br/>
        React, PostgreSQL <br/>
        Scrum 
        </BreadText>
      </Box>

      <Box>
        <BreadText bold> Duration </BreadText>
        <BreadText>
          1 semester
        </BreadText>
      </Box>

    </Box>
  );
}

function Overview() {
  return (
    <DefaultBox>
      <MetaTitle color={SIGNAL_COLOR}> OVERVIEW </MetaTitle>
      <SectionTitle> PEPPPSY: A digital tool to help patients and health personell in psychiatric care</SectionTitle>
      <BreadText>
      PEPPPSY stands for “pilot testing and evaluation of a participatory
      patient record in psychiatric care”. 
      It is based on the US-Initiative “OpenNotes” -
      which is the international movement  promoting and studying
      transparent communication in healthcare 
      <br/> <br/>
      It functions as a way for health personnel to
      communciate to their patients and write digital notes
      so that the patients. This will hopefully create more
      trust and transparency with the clinical process.
      </BreadText>

      <MetaInfo />
    </DefaultBox>
  );
}

const LEARNINGS = () => {
  return (
    <>

<SectionHighlight color={HIGHLIGHT_COLOR}>

<DefaultBox>
  <SectionTitle> What did I learn? </SectionTitle>
    <Learnings> You don't necessarily need to do a complete make over when doing a redesign </Learnings>
    <Learnings>If you can't interview the end-users you should interview as many stakoleholder indirectly connected</Learnings>
    <Learnings>When working with sensitive topics it is important that the whole group understands  what consideration should be taken</Learnings>
  </DefaultBox>
  </SectionHighlight>
    </>
  )
}

const PepppsyContent = () => {
  return (
    <Box
    mt={6}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    gap={8}
    >
      <Overview />
      <MyRole />
      <Research/>
      <Workflow />
      <WorkDone />
      <Redesign />
      <LEARNINGS />
    </Box>
  )
}

export default PepppsyContent
