import * as React from 'react'
import NavBar from '../components/Navbar'

import {
   ScrollBarDiv, IndexBox,GlobalStyle, BackgroundBox, DefaultBox
} from '../styled/Utils'
import { Box } from '@mui/system'


import Projects from './Projects'





const Index = () => {
  return (
    <ScrollBarDiv>
      <BackgroundBox>
      <GlobalStyle />


      <NavBar />
      <IndexBox>
      <DefaultBox>
        <Box pb={10}>

        <Projects/>
        </Box>
   

      </DefaultBox>
      </IndexBox>
    </BackgroundBox>
    </ScrollBarDiv>

  )
}

export default Index
