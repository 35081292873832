import React, {useEffect} from 'react'
import {
  GlobalStyle,
  DefaultBox,
  IndexBox,
  BackgroundBox,
  ScrollBarDiv
} from '../../styled/Utils'
import SurgeContent from './SurgeContent'
import NavBar from '../../components/Navbar'

export default function Surge () {


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  

  return (
    <ScrollBarDiv>  
    <BackgroundBox>
      <NavBar />
      <IndexBox>
        <DefaultBox>
          <GlobalStyle />
          <SurgeContent />
        </DefaultBox>
      </IndexBox>
    </BackgroundBox>
    </ScrollBarDiv>
  )
}
