import * as React from 'react'
import NavBar from '../components/Navbar'
import Box from '@mui/material/Box'
import styled from 'styled-components'
import {
   BreadText, ScrollBarDiv, IndexBox,GlobalStyle, BackgroundBox, WorkSummaryTitle, MetaTitle, Tags, DefaultBox
} from '../styled/Utils'
import { Link } from 'react-router-dom'
import CardMedia from '@mui/material/CardMedia';

import DesignSystemThumb from './images/workThumb/DesignSystemThumb.png'
import LovdataWork from './images/workThumb/LovdataWork.jpg'
import SchibstedWork from './images/workThumb/SchibstedWork.png'
import SurgeWork from './images/workThumb/SurgeWork.png'
import PEPPPSYWork from './images/workThumb/PEPPPSYWork.png'
import StriseWork from './images/workThumb/StriseWork.png'
import MPCThumb from './images/workThumb/MPCThumb.png'


const WorkFlexBox = styled.div`
    display:flex;
    flex-direction: column;
    justify-items: 'center' ;
    align-items:'center' ;
    gap: 6px;
    width: 400px ;
    @media (max-width: 768px) {
    order: 2;
    width: 100%;
  }

`;

const WorkTitle = styled(MetaTitle)`
    font-size: 18px;
    letter-spacing: inherit;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;
    display:flex; 
    flex-direction: row;
    height:300;
    justify-content:space-between;
    align-items:center;

    @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FadedColor = "#C5C5C5"
const HIGHLIGHT_COLOR = '#E8F5FF';


function DesignSystem(){
  return(
    <StyledLink to='/Work/DesignSystem'>
        <CardMedia
      component="img"
      alt="Design System Thumbnail"
      src={DesignSystemThumb}
      sx={{ width:'263px', height:'263px'}}
    />
    <WorkFlexBox>
    <WorkSummaryTitle color={FadedColor}>Summer internship</WorkSummaryTitle>
    <WorkTitle>Making and testing a new design system for Lovdata</WorkTitle>
      <Box display={'flex'} gap={3} pt={1} pb={2}>
      <Tags color={HIGHLIGHT_COLOR}> UI Design</Tags>
      <Tags color={HIGHLIGHT_COLOR}> UX Design</Tags>
      <Tags color={HIGHLIGHT_COLOR}> Design System</Tags>

      </Box>
      <BreadText>
        This summer internship consisted in making a design system for Lovdata.
        As well as redesigning two internal sites to test the newly made design system
      </BreadText>
    </WorkFlexBox>
  
      </StyledLink>
  )
}



function Lovdata(){
  return(
    <StyledLink to='/Work/Lovdata'>
    <WorkFlexBox>
    <WorkSummaryTitle color={FadedColor}>NTNU Course</WorkSummaryTitle>
    <WorkTitle>Redesigning LOVDATA PRO for students</WorkTitle>
      <Box display={'flex'} gap={3} pt={1} pb={2}>
      <Tags color={HIGHLIGHT_COLOR}> UX Research</Tags>
      <Tags color={HIGHLIGHT_COLOR}> User interviews</Tags>
      <Tags color={HIGHLIGHT_COLOR}> NTNU </Tags>
      </Box>
      <BreadText>
        In this project I redesigned Lovdata PRO, a tool used by everyone in law, 
         with students in mind 

      </BreadText>
    </WorkFlexBox>
    <CardMedia
      component="img"
      alt="Lovdata"
      src={LovdataWork}
      sx={{ width:'263px', height:'263px'}}
    />
      </StyledLink>
  )
}



function Schibsted(){
  return(
    <StyledLink to='/Work/Schibsted'>
      
    <CardMedia
      component="img"
      alt="Schibsted"
      src={SchibstedWork}
      sx={{ width:'263px', height:'263px'}}
    />

    <WorkFlexBox>
    <WorkSummaryTitle color={FadedColor}>Summer Internship</WorkSummaryTitle>
    <WorkTitle>Does audio articles have a future in Aftenposten?</WorkTitle>
      <Box display={'flex'} gap={3} pt={1} pb={2}>
      <Tags color={HIGHLIGHT_COLOR}> UX Research</Tags>
      <Tags color={HIGHLIGHT_COLOR}> Developing</Tags>
      <Tags color={HIGHLIGHT_COLOR}> Internship</Tags>
      </Box>
      <BreadText> 
        Through my summer internship in Schibsted
        my team decided to ask this question
        using several different tests</BreadText>
    </WorkFlexBox>
      </StyledLink>
  )
}



function Strise(){
  return(
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} height={300} >
    <WorkFlexBox>
    <WorkSummaryTitle color={FadedColor}>Developer and Design Internship</WorkSummaryTitle>
    <WorkTitle> Strise: A KYC AI based app</WorkTitle>
      <Box display={'flex'} gap={3} pt={1} pb={2}>
      <Tags color={HIGHLIGHT_COLOR}> Developing</Tags>
      <Tags color={HIGHLIGHT_COLOR}> UX/UI design</Tags>
      <Tags color={HIGHLIGHT_COLOR}> Internship</Tags>
      </Box>
      <BreadText> 
        Worked as an in-house developer and designer.
      </BreadText>
    <WorkSummaryTitle color={FadedColor}>Coming soon </WorkSummaryTitle>
    </WorkFlexBox>
    <CardMedia
      component="img"
      alt="Strise Work"
      src={StriseWork}
      sx={{ width:'263px', height:'263px'}}
    />
      </Box>
  )
}

function Surge(){
  return(
    <StyledLink to='/Work/Surge'>
  
    <WorkFlexBox>
    <WorkSummaryTitle color={FadedColor}>Ntnu course</WorkSummaryTitle>
    <WorkTitle>Surge: An app to help reduce obesity </WorkTitle>
      <Box display={'flex'} gap={3} pt={1} pb={2}>
      <Tags color={HIGHLIGHT_COLOR}> UX Research</Tags>
      <Tags color={HIGHLIGHT_COLOR}> UX/UI design</Tags>
      <Tags color={HIGHLIGHT_COLOR}> NTNU</Tags>
      </Box>
      <BreadText> 
      A personalized tool to help resist urges by
      providing activities to divert mind and get a
      better understanding of their cravings and
      patterns by tracking and analyzing them.
    </BreadText>
    </WorkFlexBox>
    <CardMedia
      component="img"
      alt="Surge"
      src={SurgeWork}
      sx={{ width:'263px', height:'263px', 'marginBottom': 3}}
    />
      </StyledLink>
  )
}

function MPCatering(){
  return(
    <StyledLink to='/Work/MPCatering'>
   <CardMedia
      component="img"
      alt="Macchu Picchu Catering Thumb"
      src={MPCThumb}
      sx={{ width:'263px', height:'263px'}}
     
    />

    <WorkFlexBox>
    <WorkSummaryTitle color={FadedColor}>NTNU COURSE</WorkSummaryTitle>
    <WorkTitle> Macchu Picchu Catering</WorkTitle>
      <Box display={'flex'} gap={3} pt={1} pb={2}>
      <Tags color={HIGHLIGHT_COLOR}> UX/UI design</Tags>
      <Tags color={HIGHLIGHT_COLOR}> Developing</Tags>
      <Tags color={HIGHLIGHT_COLOR}> NTNU</Tags>
      </Box>
      <BreadText> 
        An introduction to Figma by making a website and style guide for a
        fictive company of your choice. <br/>
        My choice: A peruvian catering site
    </BreadText>
    </WorkFlexBox>
   

      </StyledLink>
  )
}

function PEPPPSY(){
  return(
    <StyledLink to='/Work/PEPPPSY'>
 
    <WorkFlexBox>
    <WorkSummaryTitle color={FadedColor}>Informatics bachelor</WorkSummaryTitle>
    <WorkTitle>PEPPPSY: A Digital communcation tool for patients
    and health personnel in psychiatric care</WorkTitle>
      <Box display={'flex'} gap={3} pt={1} pb={2}>
      <Tags color={HIGHLIGHT_COLOR}> UX/UI design</Tags>
      <Tags color={HIGHLIGHT_COLOR}> Developing</Tags>
      <Tags color={HIGHLIGHT_COLOR}> NTNU</Tags>
      </Box>
      <BreadText> 
        My informatics bachelor which consisted in further developing
        a prototype to help patients and health personell in psychiatric care
    </BreadText>
    </WorkFlexBox>

    <CardMedia
      component="img"
      alt="PEPPPSY"
      src={PEPPPSYWork}
      sx={{ width:'263px', height:'263px'}}
    />

   

      </StyledLink>
  )
}

const Index = () => {
  return (
    <ScrollBarDiv>

  <BackgroundBox>
      <GlobalStyle />

      <NavBar />
      <IndexBox>
      <DefaultBox>
     <Box display={'flex'} flexDirection={'column'} gap={6} mb={10}>
      <DesignSystem />
      <Lovdata />
      <Schibsted/>
      <Surge/>
      <MPCatering />
      <PEPPPSY/>
      {/* <Strise/> */}
     </Box>
      </DefaultBox>
      </IndexBox>
    </BackgroundBox>
    </ScrollBarDiv>

  )
}

export default Index
