import React from 'react'
import { Box } from '@mui/system'
import { BreadText, Learnings, SectionHighlight, CustomButton, Quote, DefaultBox,
 MetaTitle, SectionTitle, TextAndMedia, MediaBox
} from '../../styled/Utils'
import { createTheme } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia';
import AFFINITY from '../images/surge/Affinity.png'
import MINDMAP from '../images/surge/MindMap.png'
import PAPERPROTOTYPE from '../images/surge/PaperPrototype.png'
import CoverPhoto from '../images/surge/CoverPhoto.png'
import SurgeActivity from  '../images/surge/Surge_activity.mp4'
import SurgeStat from '../images/surge/Surge_stat.mp4'
import DD from '../images/surge/DoubleDiamond.png'
import PERSONA from '../images/surge/Persona.png'
import styled from 'styled-components';
const SIGNAL_COLOR = "#534FD6";
const HIGHLIGHT_COLOR = "#E8E8FF";

const theme = createTheme({
  main: {
    palette: {
      primary: SIGNAL_COLOR,
      secondary: HIGHLIGHT_COLOR,
    },
  },
});




function FinalIdea(){

  return(
    <SectionHighlight color={HIGHLIGHT_COLOR}>
    <DefaultBox>
      <MetaTitle color={SIGNAL_COLOR}> Final Idea </MetaTitle>
      <SectionTitle> Surge </SectionTitle>
      <BreadText>
      Our final idea is an app called Surge. It is
          designed to help the user resist cravings
          by providing healthy alternative activities.
          Surge also tracks these cravings so that the
          user can easily identify patterns in their
          eating habits. Surge is a tool to help you create a mindful
          relationship with your cravings. It lets you
          track the what’s, why’s, and when’s of your
          eating habits. It provides fun activities
          to help you take your mind off whatever
          it is you want to indulge in. The careful
          balance between awareness and distraction
          provides the ultimate defence against
          overindulgence.
      </BreadText>

      <Box display={'flex'} justifyContent={'center'} mt={6}>
      <CustomButton
        theme={theme}
        variant="outlined"
        target="_blank"
        href="https://www.figma.com/proto/LcUHb9V8kvOwkqRnWTkaan/Surge-Proto---Mikkels-Copy?page-id=3%3A3&node-id=369%3A1666&starting-point-node-id=369%3A1666"
        >
        Click here prototype
      </CustomButton>
      </Box>
    </DefaultBox>
  </SectionHighlight>
  )
}



function UsabilityTesting(){
  return(
    <DefaultBox>
    {/* <MetaTitle color={SIGNAL_COLOR}> Deliver and Listen - iterations </MetaTitle> */}
    <SectionTitle>Usability Testing</SectionTitle>
    <BreadText>
      When the first prototype was finished, we
      went to the next phase, namely usability
      testing. This is an important method in the
      iteration process, since it helps us identify
      the parts of our interface that frustrate and
      confuse people and makes it easy to fix and
      retest until the app works flawlessly. We
      gathered participants from our user group,
      and used a technique called «Think-aloud
      protocol», more specifically concurrent
      think-aloud, where “the participants work
      through tasks while articulating what he or
      she is doing, thinking and feeling.
      Based on everything we had gathered 
      so far we started creating a high fidelity prototype.
    </BreadText>
    </DefaultBox>
  )
}

function DeliverAndListen(){
  return(
    <DefaultBox>
    <MetaTitle color={SIGNAL_COLOR}> Deliver and Listen - iterations </MetaTitle>
    <SectionTitle>Heuristic evaluation</SectionTitle>
    <BreadText>
      Moving on to the final part of the double diamond,
      it was time for “Deliver and Listen”.
      When our paper prototype was finished,
      we proceeded with a method called “Heuristic evaluation”,
      where we invited a group of design students to help us detect
      usability problems before user testing. Even though this was a
      low-fidelity prototype, it gave us a lot of information to work on.
      Some screens in our app were a bit confusing and weren’t as intuitive as we first thought.
    </BreadText>

    <TextAndMedia sx={{justifyContent:'space-between'}}>
      <Box  my={2} p={1} bgcolor={HIGHLIGHT_COLOR} maxWidth={240} height={'fit-content'}><Quote>It sounds fun and helpful</Quote></Box>
      <Box my={2} p={1}  bgcolor={HIGHLIGHT_COLOR} maxWidth={240} height={'fit-content'}><Quote>The activities need to be realistic and varied</Quote> </Box>
      <Box my={2} p={1}  bgcolor={HIGHLIGHT_COLOR} maxWidth={240} height={'fit-content'}><Quote>Are the activities specific to the cravings/triggers?
          That would be nice.</Quote></Box>
    </TextAndMedia>
    </DefaultBox>
  )
}

function PaperProtoType(){
  return (
    <DefaultBox>
      <Box
        component='img'
        maxWidth='100%'
        alt='Paper prototype'
        src={PAPERPROTOTYPE}
        borderRadius='16px'
      />
    </DefaultBox>
  )
}

function DevelopAndTesting(){

  return(
    <DefaultBox>
    <MetaTitle color={SIGNAL_COLOR}> DEVELOP AND TESTING </MetaTitle>
    <SectionTitle>Prototyping with paper</SectionTitle>
    <BreadText>
    Moving on to the next part of the double diamond
    “develop and testing”, we
    used the method “how might we” to
    help us further understand the root of
    the problem.We then went forward and
    started brainstorming with a method called
    “crazy 8”, to generate crazy ideas, where
    no idea is too ridiculous.

    We next moved on to working with the site
    structure and used MSCW to prioritize
    the requirements we previously listed.
    After having a broad
    idea of the kind of interface we wanted, we
    started sketching wireframes on paper.
    </BreadText>
    </DefaultBox>
  )
}

function ProblemStatement(){
  return(

    <SectionHighlight color={HIGHLIGHT_COLOR}>
    <DefaultBox>
      <MetaTitle color={SIGNAL_COLOR}> Problem Statements</MetaTitle>

      <Box
            display="flex"
            flexDirection="column"
            mt={3}
      >
      <Learnings>Breaking old habits is difficult</Learnings>
      <Learnings>Inner motivation is essential</Learnings>
      <Learnings>A stressful lifestyle makes exercising and eating healthy hard</Learnings> 
      </Box>
    </DefaultBox>
  </SectionHighlight>

  )
}

function Persona(){
  return(
    <DefaultBox>
    <MetaTitle color={SIGNAL_COLOR}> PERSONA </MetaTitle>
    <SectionTitle>Meet our persona: Sara Winge</SectionTitle>
    <BreadText>
      After defining the problem we could then create a persona we felt fit the project.
      After creating our persona we had a workshop where we wrote
      all the key problem statements on sheets of paper.
      After picking a main problem statement we had a 2-minute
      session where we all came up with different problem scenarios
      that fit the problem statement.

    </BreadText>

   

    <TextAndMedia mt={6} sx={{justifyContent: 'space-between'}} width={1}>
    <CardMedia
        component="img"
        alt="Affinity Diagram"
        src={PERSONA}
        sx={{ width:'auto', height:'30vw'}}
      />

      <Box display={'flex'} flexDirection={'column'} width={1} px={10}>
        <MetaTitle>Bio</MetaTitle>
        <BreadText>
        Thea Winge is a 26 year old sociology student that studies at UiO.
        Her interest are snowboarding, improv theater and left wing politics.
        </BreadText>

        <MetaTitle>Goals</MetaTitle>
        <BreadText>
        She used to snowboard professionally,
        but stopped when she started studying.
        Wants to get back to her old physical
        strength and control of her body.
        </BreadText>

        <MetaTitle>Frustration</MetaTitle>
        <BreadText>
        Not being able to move like she used to.
        Misses feeling strong, fit and active.
        Not fitting into clothes. Not seeing results
        </BreadText>
      </Box>

    </TextAndMedia>
  </DefaultBox>



  )
}

function AffinityAndPowerStatement(){

  return(
    <DefaultBox>
      <TextAndMedia sx={{justifyContent: 'space-between'}} gap={10}>
      <CardMedia
        component="img"
        alt="Affinity Diagram"
        src={AFFINITY}
        width={1}
      />
      <CardMedia
        component="img"
        alt="Mindmap"
        src={MINDMAP}
        width={1}

      />
      </TextAndMedia>

    </DefaultBox>
  )
}

function ExploreAndDefine(){

  return(
    <DefaultBox>
    <MetaTitle color={SIGNAL_COLOR}> EXPLORE AND DEFINE </MetaTitle>
    <SectionTitle>Sorting and defining the problems</SectionTitle>
    <BreadText>
      With our newfound understanding of the problem and user-group we 
      started sorting through all the information and scoping down 
      the focus.We compressed all the interviews we and grouped our
      key findings into 7 categories and created a
      power statement for each of them.
    </BreadText>
  </DefaultBox>
  )
}

function ChoosingTargetGroup(){
  return(
    <DefaultBox>
    <MetaTitle color={SIGNAL_COLOR}> CHOOSING TARGET GROUP </MetaTitle>
    <SectionTitle> Young adults as an user group </SectionTitle>
    <BreadText>
    It is claimed that the best way to reduce obesity in adults is to focus
    on obese children and teens, as they are more likely to become obese as adults,
    compared to normal BMI children. However, reducing childhood obesity is almost 
    entirely the responsibility of the parent or guardian of said child. 
    <br/><br/>
    We decided to focus on young adults and students since most of them
    have recently moved out to live on their own, and with the newly found
    freedom new bad habits can easily be formed. It is reported that as
    adolescents transition to young adulthood, they engage in significantly 
    fewer healthy behaviors. This study clearly identifies the transition to
    adulthood as an important point of healthy behavior
    decline that likely contributes to the development of
    chronic disease and obesity later in life.
    That is why we decided to focus on young adults.
    </BreadText>
  </DefaultBox>
  )
}


function Research(){

  return(
    <DefaultBox>
    <MetaTitle color={SIGNAL_COLOR}> Research </MetaTitle>
    <SectionTitle> Interview </SectionTitle>
    <BreadText>
      We began by doing individual secondary research on obesity.
      This gave us a good basis for the later subject interviews.
      We also chose our target user group. After having broadened our
      understanding of obesity and seen the varied approaches to
      helping people overcome it, we started detailing our contextual inquiry. 
      We then planned interviews with the same questions.
      <br/>
      After completing the interviews with our overweight subjects,
      we compared our data and mapped out the key findings of our research.
      It turned out eventhough our interview subjects came from different background,
      both in terms of age, culture and gender, they faced many of the same problems.
    </BreadText>
  </DefaultBox>
  )
}

function DoubleDiamond(){

  return(
    <CardMedia
    component="img"
    alt="green iguana"
    src={DD}
    sx={{ width:'70vw'}}
  />
  )
}

function Process(){
  return(
    <SectionHighlight color={HIGHLIGHT_COLOR}>
    <DefaultBox>
      <MetaTitle color={SIGNAL_COLOR}> Process </MetaTitle>
      <SectionTitle> How did we end up with Surge? </SectionTitle>
      <BreadText>
        The main aim of the course was to learn human-centered design
        through the use of the double diamond framework.
      </BreadText>
    </DefaultBox>
  </SectionHighlight>
  )
}




function MindfulEating(){
  return(
    <DefaultBox>
    <TextAndMedia>
    <MediaBox width={1}>
    <CardMedia
      component="video"
      alt="App screen record video about mindful eating"
      src={SurgeStat}
      autoPlay
      playsInline
      height={'500px'}
      sx={{ width:'auto'}}
    />
    </MediaBox >
    <Box width={1} alignSelf={'center'}>
      <SectionTitle> Mindful eating </SectionTitle>
      <BreadText>
        An important aspect of weight loss is the concept of eating mindfully.
        Eating virtually always entails some level of conscious decisions,
        yet most of them are highly conditioned, if not automatic,
        and are sensitive to emotional state.
        Being aware of what you are eating and why is
        therefore a crucial aspect of weight loss.

        Therefore, the app provides a way of logging when you eat based on craving,
        rather than hunger.

      </BreadText>
    </Box>

    </TextAndMedia>

  </DefaultBox>
  )
}




function ActivitiesAndMentalTraining(){
  return(
    <DefaultBox>
      <TextAndMedia>
      <MediaBox width={1} order={-1}>
      <CardMedia
        component="video"
        alt="App screen record video about Activities"
        src={SurgeActivity}
        autoPlay
        playsInline
        height={'500px'}
        sx={{ width:'auto'}}
      />
      </MediaBox>
      <Box width={1} alignSelf={'center'}>
        <SectionTitle> Activities and mental training</SectionTitle>
        <BreadText>
        By not indulging in urges and instead doing an activity,
        you replace the dopamine you get from eating with dopamine
        provided by the satisfaction you get from doing a fun activity of your liking.
        This helps by both training your self-discipline
        and also rewiring the trigger-based food consumption
        that leads to calorie surplus.
        </BreadText>
      </Box>
      </TextAndMedia>
    </DefaultBox>
  )
}


function Solution(){
  return(
    <DefaultBox>
    <MetaTitle color={SIGNAL_COLOR}> Solution </MetaTitle>
    <SectionTitle> 
      Surge: An app to help prevent obesity through mindfulness
        and pattern recognition
    </SectionTitle>
  </DefaultBox>
  )
}


function DesignChallenge(){
  return(
    <SectionHighlight color={HIGHLIGHT_COLOR}>
    <DefaultBox>
      <MetaTitle color={SIGNAL_COLOR}> DESIGN CHALLENGE </MetaTitle>
      <SectionTitle> How might we help people stay motivated while breaking old habits? </SectionTitle>
    </DefaultBox>
  </SectionHighlight>
  )
}

function Problem() {
  return(
    <DefaultBox>
    <MetaTitle color={SIGNAL_COLOR}> Problem </MetaTitle>
    <SectionTitle> 
      Unhealthy urges are hard to resist, especially when they become habits. 
      In terms of food and drink, this may lead to becoming overweight and an 
      unhealthy lifestyle </SectionTitle>
  </DefaultBox>
  )
}


function MetaInfo() {
  return (
    <Box display="flex" justifyContent="space-between" mt={6} flexWrap={'wrap'}>
      <Box>
        <BreadText bold> Role </BreadText>
        <BreadText>
        UX & UI-Designer
        <br/>
        Researcher
        </BreadText>
      </Box>

      <Box>
        <BreadText bold> Team </BreadText>
        <BreadText>
          5 Design Students
        </BreadText>
      </Box>

      <Box>
        <BreadText bold> Tools & Keywords </BreadText>
        <BreadText>
          Figma
          <br/>
          Double Diamond
          <br/>
          Human-Centered Design
        </BreadText>
      </Box>

      <Box>
        <BreadText bold> Duration </BreadText>
        <BreadText>
          1 Semester
        </BreadText>
      </Box>

    </Box>
  );
}
function Overview() {
  return (
    <DefaultBox>
      <MetaTitle color={SIGNAL_COLOR}> OVERVIEW </MetaTitle>
      <SectionTitle>An app to help prevent obesity through mindfulness
        and pattern recognition</SectionTitle>
      <BreadText>
      Surge was a prototype of a digital product as
      a solution to help avoid or reduce obesity in people.

      The aim of the project was to learn to solve real life problems
      by applying human-centered design process to design digital
      solution and using technology as an enabler to solve the problem.
      </BreadText>
      <MetaInfo />
    </DefaultBox>
  );
}

const SurgeContent = () => {
  return (
  <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={8}     
    >
    <Box
      component='img'
      maxWidth='70vw'
      marginLeft={'10%'}
      alt='Surge Cover Photo'
      src={CoverPhoto}
      borderRadius='2px'/>

      <Overview />
      <Problem />
      <DesignChallenge />
      <Solution />
      <ActivitiesAndMentalTraining/>
      <MindfulEating/>  
      <Process />
      <DoubleDiamond />
      <Research />
      <ChoosingTargetGroup />
      <ExploreAndDefine/> 
      <AffinityAndPowerStatement />
      <Persona />
      <ProblemStatement />
      <DevelopAndTesting />
      <PaperProtoType/>
      <DeliverAndListen/>
      <UsabilityTesting/>
      <FinalIdea />
      </Box>

  )
}

export default SurgeContent
