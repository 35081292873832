import * as React from 'react'
import {
   BreadText, TextAndMedia, CenterBox,  MetaTitle, SectionTitle,SectionHighlight, DefaultBox
} from '../../styled/Utils'
import { Box, maxWidth } from '@mui/system';
import { createTheme } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia';
import DesignSystemCover from '../images/DesignSystem/DesignSystemCover.png';
import LovdataServices from '../images/DesignSystem/LovdataServices.png';
import Atoms from '../images/DesignSystem/Atoms.png';
import Molecules from '../images/DesignSystem/Molecules.png';
import Organism from '../images/DesignSystem/Organism.png';
import EksAdm1 from '../images/DesignSystem/EksAdm1.png';
import EksAdm2 from '../images/DesignSystem/EksAdm2.png';
import ResultEksAdm1 from '../images/DesignSystem/ResultEksAdm1.jpg';
import EgneTitler from '../images/DesignSystem/EgneTitler.gif';
import BadUtvalg1 from '../images/DesignSystem/BadUtvalg1.png';
import BadUtvalg2 from '../images/DesignSystem/BadUtvalg2.png';
import EgneUtvalg from '../images/DesignSystem/EgneUtvalg.gif';
import Eksamensstatistikk from '../images/DesignSystem/Eksamensstatistikk.png';
import Eksamensoversikt from '../images/DesignSystem/Eksamensoversikt.png';
import EksamensoversiktExam from '../images/DesignSystem/EksamensoversiktExam.png';


const SIGNAL_COLOR = '#4A0D17';
const HIGHLIGHT_COLOR = '#FCF3F4';



const theme = createTheme({
  main: {
    palette: {
      primary: SIGNAL_COLOR,
      secondary: HIGHLIGHT_COLOR,
    },
  },
});




function Overview() {
  return (
    <DefaultBox gap={4}>
    <DefaultBox>
      <MetaTitle color={SIGNAL_COLOR}> OVERVIEW </MetaTitle>
      <SectionTitle> Creating and testing a design system for Lovdata</SectionTitle>
      <BreadText>
      After completing a course at NTNU with Lovdata as my external partner I was 
      asked if I would like to have a summer internship. 
      Lovdata did not have any in-house designers nor a design system, 
      which became my task for the summer. First part of the internship was 
      dedicated to creating the design system while the last part was used 
      for testing the design system I had created by redesigning to website for internal use.
        <br />
        <br />
        Lovdata PRO is a digital tool which contains all legal documents in norwegian history,
        even dating back to the 1800s. The main functionality is of course search, 
        with secondary functionality such as making own folders, highlighting and 
        marking legal documents.
      </BreadText>
    </DefaultBox>
      <MetaInfo />
    </DefaultBox>
  );
}


function MetaInfo() {
  return (
    <DefaultBox>

    <Box display="flex" justifyContent="space-between" >
      <Box>
        <BreadText bold> Role </BreadText>
        <BreadText>
          UX designer
          <br />
          UI designer
        </BreadText>
      </Box>

      <Box>
        <BreadText bold> Team </BreadText>
        <BreadText>
          Me
        </BreadText>
      </Box>

      <Box>
        <BreadText bold> Tools & Keywords </BreadText>
        <BreadText>
        Figma <br/>
        Design System <br/>
        
        </BreadText>
      </Box>

      <Box>
        <BreadText bold> Duration </BreadText>
        <BreadText>
          Summer 2023
        </BreadText>
      </Box>
    </Box>
    </DefaultBox>

  );
}

function DesignChallenge(){
  return(
    <SectionHighlight color={SIGNAL_COLOR}>
    <DefaultBox>
      <MetaTitle color={HIGHLIGHT_COLOR}> DESIGN CHALLENGE </MetaTitle>
      <SectionTitle color={HIGHLIGHT_COLOR}> How can I create a design system lays a solid foundation for 
        further development, which is also scalable and flexible? </SectionTitle>
    </DefaultBox>



  </SectionHighlight>
  )
}

function Problem() {
  return (
    <DefaultBox gap={8}>
    <DefaultBox>
      <MetaTitle color={SIGNAL_COLOR}> PROBLEM </MetaTitle>
      <SectionTitle> Lovdata offers many services externally and internally, with no consistent design</SectionTitle>
      <BreadText>
      Lovdata owns different services such as Lovdata.no, Lovdata PRO, Europalov.no 
      and their own internal system which has no consistent design. 
      This gives an unprofessional impression and makes it difficult for 
      the users to understand the Lovdata ecosystem. 
      </BreadText>
    </DefaultBox>
    <DefaultBox
        component="img"
        alt="Design System Cover Photo"
        src={LovdataServices}
      />
    </DefaultBox>
  );
}


function Solution(){
  return(
    <SectionHighlight color={HIGHLIGHT_COLOR} gap={'4rem'}>
    <DefaultBox>
      <MetaTitle color={SIGNAL_COLOR}> Solution </MetaTitle>
      <SectionTitle> Look at existing design systems and simplify! </SectionTitle>
      <BreadText>
      While it is tempting to look at big design system such as Uber, AWS, Amazon, etc. 
      It is easy to carried away and imagine a world where you have created design system 
      that is so flexible and scalable that it can be used in every situation ever. 
      However, with limited time I had to map out the I needed to complete the 
      second task which was to redesign an internal page as well as what is 
      the industry standard.
      </BreadText>
    </DefaultBox>


    <Box minWidth={'100vw'} display={'flex'} justifyContent={'space-around'} >
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} width={'fit-content'} gap={1}>
        <MetaTitle> Atoms </MetaTitle>
        <CardMedia
          component="img"
          alt="Design System Atom"
          src={Atoms}
          sx={{ width:'303px', height:'348px'}}
        />
      </Box>
      

      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} width={'fit-content'} gap={1}>
        <MetaTitle> Molecules </MetaTitle>
        <CardMedia
          component="img"
          alt="Design System Atom"
          src={Molecules}
          sx={{ width:'303px', height:'348px'}}
        />
      </Box>

      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} width={'fit-content'} gap={1}>
        <MetaTitle> Organism </MetaTitle>
        <CardMedia
          component="img"
          alt="Design System Atom"
          src={Organism}
          sx={{ width:'303px', height:'348px'}}
        />
      </Box>


    </Box>

  </SectionHighlight>
  )
}

function TestingRedesign1() {
  return (
    <DefaultBox gap={8}>
    <DefaultBox>
      <MetaTitle color={SIGNAL_COLOR}> Testing & Redesign 1 </MetaTitle>
      <SectionTitle> Eksamensadministrasjon </SectionTitle>
      <BreadText>
        In addition to creating a design system, I was asked to redesign 
        two internal services to see if the design system worked. 
        During the redesign process I found out that design system was 
        lacking some components, then I went in and added those.

        The first website I was asked to redesign was eksamensadministrasjon 
        which is a tool used by professor and faculty members at law schools to 
        decide what type of legal documents and personal notes a student should 
        have access to on the given exam.
      </BreadText>
    </DefaultBox>
    <DefaultBox
        component="img"
        alt="Eksamensadministrasjon1 Photo"
        src={EksAdm1}
      />
       <DefaultBox
        component="img"
        alt="Eksamensadministrasjon2 Photo"
        src={EksAdm2}
      />



    <BreadText>
    I started by interviewing the Lovdata staff who were called whenever a 
    faculty member at an university had any questions about how eksamensadministrasjon worked. 
    I asked questions like: What is the purpose of this tool, who uses this tool, 
    what is the main problem user faces with this tool, what are the consequences 
    if this tool is used wrong? 
    <br/><br/>
    I wanted to interview the users and university staff about this, however, 
    it was during the summer break so it proved rather difficult to find someone to test this on. 
    However, after interviewing I formulated power statements which acted as 
    focus points during the design process
      </BreadText>

    <PowerStatements />
    </DefaultBox>

    
  );
}

function PowerStatements() {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignContent="space-between"
      flexWrap={'wrap'}
    >

      <Box
        sx={{ backgroundColor: SIGNAL_COLOR }}
        px={3}
        py={3}
        width={1 / 4}
        borderRadius={2}
      >
        <BreadText color='white' textAlign={'center'}>
        Law students are cunning and will find ways of 
        tricking the system if not properly set up 
        </BreadText>
      </Box>

      <Box
        sx={{ backgroundColor: SIGNAL_COLOR }}
        px={3}
        py={3}
        width={1 / 4}
        borderRadius={2}
      >
        <BreadText color='white' textAlign={'center'}>
        Sometimes it is difficult for faculty member to 
        understand what they are allowing and not allowing on the exam
        </BreadText>
      </Box>

      <Box
        sx={{ backgroundColor: SIGNAL_COLOR }}
        px={3}
        py={3}
        width={1 / 4}
        borderRadius={2}
      >
        <BreadText color='white' textAlign={'center'}>
        Different unversities and professors have different exam 
        rules and need to set different rules for the students 
        </BreadText>
      </Box>
    </Box>

  );
}


function ResultRedesign1(){
  return(
    <SectionHighlight color={HIGHLIGHT_COLOR} gap={'4rem'}>
    <DefaultBox>
      <MetaTitle color={SIGNAL_COLOR}> Result </MetaTitle>
      <SectionTitle> Eksamensadministrasjon 2.0 </SectionTitle>
      <BreadText>
        The result of the redesign is a tool which utlizes to the newly made design system. I decided to add functionality such as showing the change history for faculty members being able to see who have made changes, as well as a possibility to revert to the standard rules set by Lovdata.
      </BreadText>
    </DefaultBox>

    <DefaultBox
        component="img"
        alt="Result of redesign eksadmin1 Photo"
        src={ResultEksAdm1}
      />
  </SectionHighlight>
  )
}


function Highlight1(){
  return(
    <DefaultBox gap={8}>
      <Box>
      <MetaTitle color={SIGNAL_COLOR}> Highlight 1  </MetaTitle>
      <SectionTitle> Use the existing UI in Lovdata PRO to show what is being allowed </SectionTitle>
      <BreadText>
      One of the problems that the users faced was that 
      they did not have a firm understanding of what the different terms such as “utvalgstittel”, 
      “merknader” and “tittel på dokumenter” was. In order to make the user understand. 
      I merely added visualization that corresponds to what the students actually see when 
      using the tools, to show what is being affected when you check it off.
      </BreadText>
      </Box>
    
    <CenterBox>


     <img
        src={EgneTitler}
        width={'600px'}
        autoPlay
        playsInline 
      />
    </CenterBox>

    </DefaultBox>
  )
}

function Highlight2(){
  return(
    <DefaultBox gap={8}>
      <Box>
      <MetaTitle color={SIGNAL_COLOR}> Highlight 2  </MetaTitle>
      <SectionTitle> One choice forces you to make another choice </SectionTitle>
      <BreadText>
      The old eksamensadministrasjon had a problem where if you did not specify 
      tegnbegrenset utvalgstittel it meant that the student could have have whatever 
      they wanted as utvalgstittel, which meant that some students wrote helper text in 
      the title for the exam, and this way had an unfair advantage during the exam.
      <br/><br/>
      Normally in system which decides access and rights, the more boxes are checked, 
      the more rights and access does the user have. This is true for this system as well, 
      except this one instance. 
      <br/><br/>
      <BreadText bold> Which one of these do you think gives the students more access? </BreadText>
      </BreadText>
      </Box>

     <OldUtvalg />   
     <Box>  
      <BreadText>
      <BreadText bold>       Surprisingly, the option on the left gives the student 
      right to have as long titles as they want. </BreadText>
      <br/>
        Which can enable cheating. While the one on the right sets an upper limit 
        on how long the title can be. It is understanable that the users have trouble 
        understanding how this system works!
        <br/>    <br/>
        In my redesign I forced the user to decide immediatly make a decision on how long the 
        titles are allowed to be. In addition, I moved the other choices under the checkbox as 
        you cannot allow “Beskrivelse av utvalg” without first checking “Egne utvalg” which was 
        possible in the old design. 
    
      </BreadText>

      </Box>


    <CenterBox>


      <img
        src={EgneUtvalg}
        width={'600px'}
        autoPlay
        playsInline 
      />
      </CenterBox>
     



    </DefaultBox>
  )
}

function OldUtvalg(){

  return(
    <DefaultBox>
      <TextAndMedia sx={{justifyContent: 'space-between'}} >
      <CardMedia
        component="img"
        alt="Affinity Diagram"
        src={BadUtvalg1}
        sx={{maxWidth:'40%'}}
      />
      <CardMedia
        component="img"
        alt="Mindmap"
        src={BadUtvalg2}
        sx={{maxWidth:'40%'}}
      />
      </TextAndMedia>

    </DefaultBox>
  )
}


function TestingRedesign2() {
  return (
    <DefaultBox gap={8}>
    <DefaultBox>
      <MetaTitle color={SIGNAL_COLOR}> Testing & Redesign 2 </MetaTitle>
      <SectionTitle> Eksamensoversikt </SectionTitle>
      <BreadText>
      The second page I was asked to redesign was Eksamensoversikt, 
      which is used by Lovdata during exam period to know which days do 
      they need to have someone work on customer support. 
      During the exams it is crucial to have somebody at Lovdata ready to 
      jump in if there are any problems with the software. 

      It used to be call Eksamensstatistikk, but after interviewing I found out 
      that it needed a name change, since it was dealing with the future and not the past.
      </BreadText>
    </DefaultBox>
    <DefaultBox
        component="img"
        alt="Eksamensstatistikk Photo"
        src={Eksamensstatistikk}
      />
    </DefaultBox>
  );
}


function ResultRedesign2(){
  return(
    <SectionHighlight color={HIGHLIGHT_COLOR} gap={'4rem'}>
    <DefaultBox>
      <MetaTitle color={SIGNAL_COLOR}> Result </MetaTitle>
      <SectionTitle> Eksamensadministrasjon 2.0 </SectionTitle>
      <BreadText>
        The result of the redesign is a tool which utlizes to the newly made design system. I decided to add functionality such as showing the change history for faculty members being able to see who have made changes, as well as a possibility to revert to the standard rules set by Lovdata.
      </BreadText>
    </DefaultBox>

    <DefaultBox
        component="img"
        alt="Result of redesign Eksamensoversikt Photo"
        src={Eksamensoversikt}
      />
  </SectionHighlight>
  )
}


function Highlight3(){
  return(
    <DefaultBox gap={8}>
      <Box>
      <MetaTitle color={SIGNAL_COLOR}> Highlight 3  </MetaTitle>
      <SectionTitle> How does it look when there are ongoing exams? </SectionTitle>
      <BreadText>
          The main purpose of this tool is give Lovdata insight 
          in the exams to know whether or not they need to be available. 
          Sometimes the exam goes overtime and they need to be able to see 
          how many candidates are still doing the exam and how long overtime it has been.
      </BreadText>
      </Box>
    
    <CenterBox>


    <DefaultBox
        component="img"
        alt="Result of redesign Eksamensoversikt Exam Photo"
        src={EksamensoversiktExam}
      />
    </CenterBox>

    </DefaultBox>
  )
}



function Learnings(){
  return(
    <SectionHighlight color={HIGHLIGHT_COLOR} gap={'4rem'}>
    <DefaultBox>
      <MetaTitle color={SIGNAL_COLOR}> Key takeaway </MetaTitle>
      <SectionTitle> What did I learn? </SectionTitle>
      <BreadText>
      • To test how well a design system works you need to start using it as fast as possible. A design system cannot work in a vacuum.
      <br/>
      • Look at other design system for inspiration and guidance, but tailor your design system to your need
      <br/>
      • When redesigning, always ask yourself if the existing functionality is sufficient for the main purpose of the tool. If not, do not be afraid of designing stuff that requires better functionality.
      </BreadText>
    </DefaultBox>

  </SectionHighlight>
  )
}

const Index = () => {
  return (
    <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    gap={8}     

  >   
      <DefaultBox
        component="img"
        alt="Design System Cover Photo"
        src={DesignSystemCover}
      />
    

      <Overview />
      <DesignChallenge />
      <Problem />
      <Solution />
      <TestingRedesign1 />
      <ResultRedesign1 />
      <Highlight1 />
      <Highlight2 />
      <TestingRedesign2/>
      <ResultRedesign2 />
      <Highlight3 />
      <Learnings />
    </Box>
  )
}

export default Index
