import React, {useEffect} from 'react'
import Box from '@mui/material/Box'
import {
  DefaultBox,
  IndexBox, 
  BackgroundBox,
  ScrollBarDiv
} from '../../styled/Utils'
import MPContent from './MPContent'
import NavBar from '../../components/Navbar'
import MPLANDING from '../images/MPCatering/MPLanding.jpg'
import { Button } from '@mui/material';

export default function MPCatering () {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <ScrollBarDiv>

    <BackgroundBox>
      <NavBar />
      <IndexBox>
        <DefaultBox>
        <Button target="_blank" href="http://www.mpcatering.no/"> 
          <Box    
            component='img'
            maxWidth='100%'
            alt='MP Catering'
            src={MPLANDING}  
            />
        </Button>
        <MPContent />
        </DefaultBox>
      </IndexBox>
    </BackgroundBox>
  </ScrollBarDiv>
  )
}
