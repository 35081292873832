import React, {useEffect} from 'react'
import {
  IndexBox,
  BackgroundBox,
  GlobalStyle,
  ScrollBarDiv
} from '../../styled/Utils'
import SchibstedContent from './SchibstedContent'
import NavBar from '../../components/Navbar'

function Schibsted () {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
<ScrollBarDiv>

    <BackgroundBox>
      <GlobalStyle/>
      <NavBar />
      <IndexBox>
          <SchibstedContent />
      </IndexBox>
    </BackgroundBox>
</ScrollBarDiv>
  )
}

export default Schibsted
