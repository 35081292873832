
import React from 'react'
import NavBar from '../components/Navbar'
import {
  BackgroundBox,
  GlobalStyle
} from '../styled/Utils'
import LandingPage from '../pages/LandingPage'
import { Box } from '@mui/system'


const Home = () => {
  return( 
    <Box>


      <GlobalStyle />
    <BackgroundBox>  
      <NavBar />
      <LandingPage />
    </BackgroundBox>

        </Box>


    

  )
}


export default Home