import * as React from 'react'
import NavBar from '../../components/Navbar'
import {
   BreadText, ScrollBarDiv, IndexBox,GlobalStyle, BackgroundBox,SectionTitle, MetaTitle, DefaultBox
} from '../../styled/Utils'
import { Box, Container } from '@mui/system'
import Cover from '../images/projects/Light/Cover.jpg'
import Hamburger from '../images/projects/Light/Hamburger.jpg'
import Sketches from '../images/projects/Light/Sketches.jpg'
import Prints from '../images/projects/Light/Prints.jpg'


function MetaInfo() {
    return (
      <Box display="flex" justifyContent="space-between" >
        <Box>
          <BreadText bold> Context </BreadText>
          <BreadText>
            Product Design <br/>
            Injection Molding Design <br/>
            NTNU Course
          </BreadText>
        </Box>
  
        <Box>
          <BreadText bold> Team </BreadText>
          <BreadText>
            Individual
          </BreadText>
        </Box>
  
        <Box>
          <BreadText bold> Tools & Keywords </BreadText>
          <BreadText>
            Keyshot 
            <br />
            Fusion 360 
          </BreadText>
        </Box>
  
        
  
      </Box>
    );
  }
  


const Index = () => {
  return (
    <ScrollBarDiv>
    <BackgroundBox>
      <GlobalStyle/>
      <NavBar />
      <IndexBox>
        
      <DefaultBox>
      <Container sx={{'display':'flex', 'flexDirection': 'column', gap:6 }}>

      <Box
        component="img"
        maxWidth="100%"
        alt="Speaker Cover Photo"
        src={Cover}
        pb={6}
      />


      <Box width={'100%'} pb={6}> 
      <MetaTitle>A light dimmer designed for mass production</MetaTitle>
      <SectionTitle> LUMOS </SectionTitle>
      <BreadText>
        Lumos is an easy to use light switch dimmer made from plastic.
        This light switch was designed for mass production using injection mold.
      </BreadText>

      <MetaInfo/>
      </Box>
       <Box
        component="img"
        maxWidth="100%"
        alt="Speaker Cover Photo"
        src={Hamburger}
      />
        <Box
        component="img"
        maxWidth="100%"
        alt="Speaker Cover Photo"
        src={Sketches}
      />
        <Box
        component="img"
        maxWidth="100%"
        alt="Speaker Cover Photo"
        src={Prints}
      />


    </Container>




      </DefaultBox>

      </IndexBox>
    </BackgroundBox>
</ScrollBarDiv>

  )
}

export default Index
