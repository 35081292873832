import * as React from 'react'
import Box from '@mui/material/Box'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Logo from '../pages/images/assets/logo.svg'
import { GlobalStyle } from '../styled/Utils'


const StyledLink = styled(Link)`
    font-size: 16px;
    color: #000000;
    text-decoration: none;
    font-family:tablet-gothic, sans-serif;
    font-weight:400 ;
`

const LogoComponent = styled(Box)`
    width: 8vh;
    padding-left:2rem;
    object-fit:contain;

    @media (max-width: 768px) {
    display:none ;
    }
`;

const SubpageContainer  = styled(Box)`
    width: 20%;
    padding-right:3rem;
    padding-left:3rem;
    min-height:4rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
      width: 100%;
    }
    `;


const NavBar = () => {
  return (
    <>

    <Box pt={2} pb={6} sx={{ display: 'flex', flexDirection: 'row', justifyContent:'space-between'}}    

        
        >
      <Link to='/'> 
      <LogoComponent
            component='img'
            alt='Logo'
            src={Logo}
           
          />
      </Link>


      <SubpageContainer >
        <StyledLink to='/About'>About</StyledLink>
        <StyledLink to='/Projects'>Projects</StyledLink>
        <StyledLink to='/Work'>Work</StyledLink>
      </SubpageContainer>
    </Box>
    </>

  )
}

export default NavBar
