import styled, { createGlobalStyle } from 'styled-components'
import { Box } from '@mui/system'
import Button from '@mui/material/Button';
import { styled as styledMUI} from '@mui/material/styles';


export const GAP_VALUE = 6

const CRYSTAL_GREEN = '#FF0000'

export const ScrollBarDiv = styled.div`
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
`;

export const CenterBox = styled(Box)`
  display:flex;
  flex-direction:column ;
  justify-items: center;
  align-items:center ;
`;

export const DefaultBox = styled(Box)`
  width: 70vw;
  display:flex;
  flex-direction:column ;
  justify-items: center;
  @media (max-width: 768px) {
    width: 90vw;
  }


  @media (min-width: 1800px) {
    width: 40vw;
  }
`;  



export const TextAndMedia = styled(DefaultBox)`
  flex-direction: row;
  align-items: center;
  @media (max-width: 768px) {
      flex-direction: column;
    }
`;

export const MediaBox = styled(Box)`
    justify-content: ${props => props.order==-1 ? 'flex-start': 'flex-end'};
    display: inline-flex;
    order:2;
    align-self:center;
    margin-top: 40px;
    @media (max-width: 768px) {
      order: 2 !important;
      justify-content:center;
    }
`;



export const HomePageText = styled.h1`
  font-size: 36px;
  font-family:tablet-gothic, sans-serif ;
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  margin: 0;
  white-space: break-spaces;
  @media (max-width: 768px) {
    font-size: 24px;
    }
  
`

export const CustomButton = styledMUI(Button)(({ theme }) => ({
  width: 200,
  color: theme.main.palette.primary,
  borderColor: theme.main.palette.primary,
  fontWeight: 400,
  fontFamily: 'tablet-gothic',
 '&:hover': {
  backgroundColor: theme.main.palette.secondary
  }
}));

export const IndexBox = styled(Box)({
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  minHeight:'100%',
  flexDirection:'column'
})

export const BackgroundBox = styled(Box)({
  backgroundColor: '#FEFEFE'
})


export const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    overflow-x:hidden ;
  }
`
export const SectionHighlight = styled.div`
  min-width: 100vw;
  background-color: ${props => props.color};
  display:flex;
  flex-direction: column;
  align-items: center ;
  justify-content:center;
  padding-top: 5rem;
  padding-bottom: 4rem;
  gap: ${props => props.gap};
`;



export const DefaultPage = styled.div`
  max-width: 75%;
  padding-left: calc(100vw - 100%);
`

export const Title = styled.h1`
  font-size: 86px;
  src: url("https://use.typekit.net/af/7a455b/00000000000000007735e26a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/7a455b/00000000000000007735e26a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/7a455b/00000000000000007735e26a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  margin: 0;

`


export const BreadText = styled.p`
    font-family: tablet-gothic, sans-serif;
    font-weight: 200;
    font-style: normal;
    font-display: auto;
    font-style: normal;
    font-weight:${props => props.bold ? 600: 200};
    color:${props => props.color ? props.color: 'black'};
    text-align:${props => props.textAlign};
    font-stretch: normal;
    font-size: 16px;
    margin: 0;
    padding: 0;
    line-height: 24px;
`

export const Quote = styled(BreadText)`
  font-style:italic;
`;



export const Header = styled.h2`
  font-family: tablet-gothic, sans-serif;
  font-size: 24px;
  padding: 0;
  margin: 0;
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
`



export const MetaTitle = styled.h3` 
  font-family: "Komet";
  font-size: 14px;
  font-weight: 600 ;
  letter-spacing: 100% ;
  color: ${props => props.color};
  margin-bottom: 12px ;
  text-transform: ${props => props.color ? 'uppercase': 'none'};
  letter-spacing: inherit;
`;

export const WorkSummaryTitle = styled(MetaTitle)`
      letter-spacing: 2px;
      font-size: 12px;
`;

export const Tags = styled.p`
  font-size: 10px;
  padding: 8px;
  background: ${props => props.color};
  border-radius: 2px;
  font-family: "Komet";
  font-weight: 200 ;
  margin-bottom: 2px ;
  text-transform: uppercase;
  width:fit-content ;
`;



export const Learnings = styled(BreadText)`
  font-weight: 500;
  margin-bottom: 20px ;

`;

export const SectionTitle = styled.h2`
  font-size: 24px;
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  margin-bottom: 0.5rem ;
  color: ${props => props.color ? props.color : 'black'};

`;


export const SmallText = styled(BreadText)`
    font-size: 16px;
`

export const ContextTitle = styled(BreadText)`
  font-size: 26px;
  line-height: 100%;
  font-weight: 400;
  padding-top: 0.5em;
  padding-bottom: 1rem;
`


export const ContextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100rem;

`

export const ProjectContext = styled.div`
    padding: 28px;
    border-radius: 16px;
    background-color: ${CRYSTAL_GREEN} ;

    font-display: auto;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;

    li{
      margin-top:0.3rem;
    }
    ul{
      font-size: 22px;
      font-weight: 200;
    }
`

export const TwoContainer = styled.div`
  display: flex ;
  flex-direction: row ;
  height: auto;
  column-gap: 10rem;
  justify-content: space-between ;
  width: 100%;

`

export const HalfContainer = styled(Box)`
  width: 524px;
  display: flex;
  flex-direction:column;
  padding: 0;
  margin: 0;
  gap: 1em;
  
`