import * as React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'

import { HashRouter, Routes, Route, Navigate } from 'react-router-dom'

import DesignSystem from './pages/DesignSystem'
import Lovdata from './pages/Lovdata'
import Schibsted from './pages/Schibsted'
import Surge from './pages/Surge'
import Work from './pages/Work'
import ProjectIndex from './pages/ProjectIndex'
import About from './pages/About'
import PEPPPPSY from './pages/Pepppsy'
import MPCatering from './pages/MPCatering'
import Abanik from './pages/Projects/Abanik'
import Speakers from './pages/Projects/Speakers'
import Knagg from './pages/Projects/Knagg'
import Light from './pages/Projects/Light'
import LCP from './pages/Projects/LCP'
import Lysistrata from './pages/Projects/Lysistrata'


const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
  <HashRouter>
    <Routes>
      <Route path='/' element={<App />} />
      <Route path='/Work/DesignSystem' element={<DesignSystem />} />

      <Route path='/Work/Lovdata' element={<Lovdata />} />
      <Route path='/Work/Schibsted' element={<Schibsted />} />
      <Route path='/Work/Surge' element={<Surge />} />
      <Route path='/Work/PEPPPSY' element={<PEPPPPSY />} />
      <Route path='/Work/MPCatering' element={<MPCatering />} />
      <Route path='/Work' element={<Work />} />
      <Route path='/Projects' element={<ProjectIndex />} />
      <Route path='/Projects/Abanik' element={<Abanik />} />
      <Route path='/Projects/Speakers' element={<Speakers />} />
      <Route path='/Projects/Knagg' element={<Knagg />} />
      <Route path='/Projects/Light' element={<Light />} />
      <Route path='/Projects/LCP' element={<LCP />} />
      <Route path='/Projects/Lysistrata' element={<Lysistrata />} />

      <Route path='/About' element={<About />} />

      <Route path="*" element={<Navigate to="/" replace />} />

    </Routes>
  </HashRouter>
)
