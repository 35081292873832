import React from 'react';
import { Box } from '@mui/system';
import styled from 'styled-components';
import { createTheme } from '@mui/material/styles';
import {
  Learnings,TextAndMedia, MediaBox, SectionHighlight, CustomButton, Quote, DefaultBox, MetaTitle, SectionTitle, BreadText,
} from '../../styled/Utils';
import PROCESS_IMAGE from '../images/schibsted/PROCESS.png';
import AVG_LENGTH from '../images/schibsted/AVG_LENGTH.png';
import FAKEDOOR from '../images/schibsted/FAKEDOOR.gif';
import CoverPhoto from '../images/schibsted/LotsOfPhonesNOBack.png';

const SIGNAL_COLOR = '#143AC5';
const HIGHLIGHT_COLOR = '#E8F5FF';

const theme = createTheme({
  main: {
    palette: {
      primary: SIGNAL_COLOR,
      secondary: HIGHLIGHT_COLOR,
    },
  },
});



function LEARNINGS() {
  return (
    <SectionHighlight color={HIGHLIGHT_COLOR}>
      <DefaultBox>

        <Box

          display="flex"
          flexDirection="column"
        >
          <SectionTitle color={SIGNAL_COLOR} > What did I learn? </SectionTitle>
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            mt={1}
          >
            <Learnings> Research and tests are essential when making big decisions</Learnings>
            <Learnings>
              It is important to find out which
              metrics best represent an outcome
            </Learnings>
            <Learnings>
              Qualitativ research does not always
              correspond with qualitive research
            </Learnings>
          </Box>
        </Box>
      </DefaultBox>

    </SectionHighlight>

  );
}

function FAKEDOOR_GIF() {
  return (

    <Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        py={5}
      >
        <Box
          component="img"
          maxWidth="70%"
          alt="Fakefoor Test"
          src={FAKEDOOR}
          borderRadius="16px"
        />
      </Box>
    </Box>
  );
}

function FAKE_DOOR_TEST() {
  return (
    <TextAndMedia>


        <Box width={1}>
          <MetaTitle color={SIGNAL_COLOR}> Fake door test </MetaTitle>
          <SectionTitle>
            Are listeners willing to pay for
            the audio version of articles?
          </SectionTitle>
          <BreadText>
            The last test that was planned was a “fake door” test.
            The test entailed having a disabled player show up on non-subscribers and
            when they pressed the player they were presented
            a 30 second preview of the audio article,
            followed by three questions.
            They were asked if this was a feature they were willing to pay for.
            After they responded they were thanked and given access to the full audio.
            <br />

            <br />
            The test was done on a new article, with a human voice.
            Unfortunately the internship ended
            before sufficiently data was gathered, so I do not know what the outcome was.
          </BreadText>

        </Box>

        <MediaBox width={1} order={-1}>

          <CustomButton
            theme={theme}
            variant="outlined"
            target="_blank"
            href="https://www.aftenposten.no/amagasinet/i/oWMxK0/han-har-forsket-paa-lykke-og-vet-hva-som-virker-ogsaa-etter-pandemien"
          >
            Click here for fake door test
          </CustomButton>
          </MediaBox>


    </TextAndMedia>
  );
}

function AB_TEST() {
  return (
    <TextAndMedia>


        <Box width={1}>
          <MetaTitle color={SIGNAL_COLOR}>
            Human vs AI - A/B Test
          </MetaTitle>
          <SectionTitle>Readers prefer human voice, but don’t mind AI</SectionTitle>
          <BreadText>
            The main test of the internship was to see a Text-to-speech bot
            would be good enough for the users of Aftenposten.
            Our hypotethis was that people would prefer the human voice and
            would not like the bot voice at all. We did an A/B test where half of the
            audience was given the human audio and the other receive the AI voice.
            Our assumption of the preference was correct,
            however we were suprised about the how
            long the average listen length of the AI voice was.
          </BreadText>
        </Box>

        <MediaBox width={1}>
          <CustomButton
            theme={theme}
            variant="outlined"
            target="_blank"
            href="https://www.aftenposten.no/amagasinet/i/GazXKV/gutten-som-aldri-fikk-fred"
          >
            Click here for A/B Test
          </CustomButton>
        </MediaBox>


    </TextAndMedia>

  );
}

function InclinationTest() {
  return (
    <DefaultBox>
      <MetaTitle color={SIGNAL_COLOR}> Inclination Test </MetaTitle>
      <SectionTitle>Novelty or curiosity?</SectionTitle>
      <BreadText>
        Too gather more data we repeated the experiment,
        but this time we did it in production, we tested inclination
        of audio article on organic traffic from 27.07 - 07.08.
        This was to see if the key takeaways from the user
        interviews scaled up and match the general audience of Aftenposten.
        I coded the player and integrated it into the CMS of Aftenposten.

        <br />
        <br />

        A click-through rate of 3.5% was achieved with an
        average listen length of 75%.
        CTR is not the best metric for measuring the actual interest,
        however the rather high avg. listening length is a
        better indicator of the audiences actual interest.
        <br />
        <br />

        How do we know that 3,5% is good? We had a meeting
        with the Senior Product Manager of The Washington Post
        and he claimed that anything close to 1% is really good!
      </BreadText>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        pt={10}
        pb={6}
      >
        <Box
          component="img"
          maxWidth="25%"
          alt="Length Data"
          src={AVG_LENGTH}
        />
      </Box>

    </DefaultBox>
  );
}

function InterviewQuotes() {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignContent="space-between"
      py={8}
    >

      <Box
        sx={{ backgroundColor: HIGHLIGHT_COLOR }}
        px={3}
        py={3}
        width={1 / 4}
        borderRadius={2}
      >
        <Quote>
          “I want to listen to news.
          I don’t want to listen to a feature story“

          <br />

          (Female, 55)
        </Quote>
      </Box>
      <Box
        sx={{ backgroundColor: HIGHLIGHT_COLOR }}
        px={3}
        py={3}
        width={1 / 4}
        borderRadius={2}
      >
        <Quote>
          “I will not listen to half an hour of audio articles. I prefer a quick read.” (Male, 50)
        </Quote>
      </Box>
    </Box>

  );
}

function UserInterviews() {
  return (
    <DefaultBox>
      <MetaTitle color={SIGNAL_COLOR}> USER INTERVIEWS </MetaTitle>
      <SectionTitle> Sentiment of the article affects engagement </SectionTitle>
      <BreadText>
        The Adobe XD prototype was a mobile version of Aftenposten which
        included an article with audio.
        The article audio was 26 minutes long and about a boy who comitted suicide.
        We did both an inclination test, will the participant press on the article
        with and audio symbol and what do they feel about the feature?
        6 qualitative interviews for 30 min each after was done after
        letting them play around with the prototype.
        Our findings was that they did not like the theme of the article
        and they thought it was too long
      </BreadText>
      <InterviewQuotes />
    </DefaultBox>

  );
}


function ProcessCirlces() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        component="img"
        maxWidth="70vw"
        alt="PROCESS IMAGE"
        src={PROCESS_IMAGE}
      />
    </Box>
  );
}


function MyRole() {
  return (
    <DefaultBox>
      <SectionTitle>
        My Role
      </SectionTitle>
      <BreadText>
        The summer internship team consisted of one product manager who was a full time employee,
        a design intern and me, the computer science intern. My role consisted of coding and
        implementing the design proposals the team agreed on, as well as being a researcher during
        the research phase of the internship.
      </BreadText>
    </DefaultBox>
  );
}

function Process() {
  return (
    <DefaultBox>
      <MetaTitle color={SIGNAL_COLOR}> PROCESS </MetaTitle>
      <SectionTitle> Design Sprint and Testing </SectionTitle>
      <BreadText>
        In order to answer this question my team did a design
        sprint where we conducted user interviews and
        based on those user interviews
      </BreadText>

    </DefaultBox>
  );
}

function DesignChallenge() {
  return (
    <SectionHighlight color={HIGHLIGHT_COLOR}>
      <DefaultBox>
        <MetaTitle color={SIGNAL_COLOR}> DESIGN CHALLENGE </MetaTitle>
        <SectionTitle> Does audio articles have a future in Aftenposten?</SectionTitle>
        <BreadText>
          The way people consume media is changing, with audio becoming a bigger
          part of users everyday life. More and more people are reporting that their primary
          source of news is audio articles and podcasts. The question our team had to answer was,
          how will this influence Aftenposten, and how might we best prepare for this change?

        </BreadText>

      </DefaultBox>
    </SectionHighlight>
  );
}

function MetaInfo() {
  return (
    <Box display="flex" justifyContent="space-between" mt={6} flexWrap={'wrap'}>
      <Box>
        <BreadText bold> Role </BreadText>
        <BreadText>

          Developer
          <br />

          Prototyper
        </BreadText>
      </Box>

      <Box>
        <BreadText bold> Team </BreadText>
        <BreadText>
          1 Product manager
          <br />
          1 Designer
          <br />
          1 Developer
        </BreadText>
      </Box>

      <Box>
        <BreadText bold> Tools & Keywords </BreadText>
        <BreadText>
          Adobe XD
          <br />
          JS, CSS, HTML
        </BreadText>
      </Box>

      <Box>
        <BreadText bold> Duration </BreadText>
        <BreadText>
          Summer Internship at
          <br />
          Schibsted
        </BreadText>
      </Box>

    </Box>
  );
}

function Overview() {
  return (
    <DefaultBox>
      <MetaTitle color={SIGNAL_COLOR}> OVERVIEW </MetaTitle>
      <SectionTitle> Text to speech at Aftenposten</SectionTitle>
      <BreadText>

        In summer 2019, I had my first summer internship
        in the Schibsted team called Tech Experiments.
        The Tech Experiments team works across Schibsted’s brands to monitor and act on disruptive
        technology waves that may impact their business or our users expectations.
        <br />

        <br />
        This summer project was to do research and tests in production to see whether
        or not audio articles has a future in Aftenposten and if a
        Text-to-speech bot is good enough for average Aftenposten reader
      </BreadText>

      <MetaInfo />
    </DefaultBox>
  );
}

function SchibstedContent() {
  return (

    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={8}
    >
      <Box
        component="img"
        maxWidth='90vw'
        alt="Schibsted Cover Photo"
        src={CoverPhoto}
      />

      <Overview />
      <DesignChallenge />

      <MyRole />

      <Process />
      <ProcessCirlces />

      <UserInterviews />

      <InclinationTest />
      <AB_TEST />
      <FAKE_DOOR_TEST />
      <FAKEDOOR_GIF />
      <LEARNINGS />
    </Box>

  );
}

export default SchibstedContent;
