import React from 'react'
import { Box } from '@mui/system'
import { createTheme } from '@mui/material/styles';
import {
  CustomButton, DefaultBox, MetaTitle, SectionTitle, BreadText,
} from '../../styled/Utils'
import {CardMedia} from '@mui/material';
import SiteMapImage from '../images/MPCatering/SiteMapMP.jpg'
import COLOR_THEME from '../images/MPCatering/ColorTheme.jpg'
import COLOR_INSPO from '../images/MPCatering/ColorInspo.png'
import LOGO_IMAGE from '../images/MPCatering/Logo.jpg'

const SIGNAL_COLOR = '#162D19';
const HIGHLIGHT_COLOR = '#5d7060';

const theme = createTheme({
  main: {
    palette: {
      primary: SIGNAL_COLOR,
      secondary: HIGHLIGHT_COLOR,
    },
  },
});

const Logo = () => {
  return (
    <DefaultBox>
      <MetaTitle color={SIGNAL_COLOR}> Deliverable 3 </MetaTitle>
      <SectionTitle>
       Logo
      </SectionTitle>
      <BreadText>
        The logo is based on the image above.
        The image depicts an inca <i> Tumi </i> which is a sharp tool, 
        which had many different uses. <br/>
        Everything from kitchen knives, agricultural tools, 
        warrior or hunting secondary weapons, sacrificial knives, 
        barber implements, pendants, or medical tools.
        It was even used to perform historys first lobotomy.
      </BreadText>

      <CardMedia
        component="img"
        alt="MP Logo"
        src={LOGO_IMAGE}
        sx={{ width:'100%', height:'auto'}}/>


    </DefaultBox>
  )
}


const ColorTheme = () => {
  return (
    <DefaultBox>
      <MetaTitle color={SIGNAL_COLOR}> Deliverable 2 </MetaTitle>
      <SectionTitle>
       Color Theme
      </SectionTitle>
      <BreadText>
        The color chosen was based of the images found when searching Peru.
        The saturated colors are meant to reflect the colorful country and landscape
        which Peru has. The green from the grass in Macchu Picchu,
        and the other colors from the gold ornaments and tools, used by
        the incas. 
      </BreadText>

      <CardMedia
        component="img"
        alt="Color theme"
        src={COLOR_THEME}
        sx={{ width:'100%', height:'auto'}}/>

      <CardMedia
        component="img"
        alt="Color Inspo"
        src={COLOR_INSPO}
        sx={{ width:'100%', height:'auto'}}/>
    </DefaultBox>
  )
}

const SiteMap = () => {
  return (
    <DefaultBox>
      <MetaTitle color={SIGNAL_COLOR}> Deliverable 1 </MetaTitle>
      <SectionTitle>
       Sitemap
      </SectionTitle>
      <BreadText>
        One of tools we were introduced to, was a sitemap.
        This site map is meant to show how to navigate to the different pages.
      </BreadText>

      <Box mt={6}>

      <CardMedia
        component="img"
        alt="Site Map"
        src={SiteMapImage}
        sx={{ width:'100%', height:'auto'}}/>
        </Box>
    </DefaultBox>
  )
}

function MetaInfo() {
  return (
    <Box display="flex" justifyContent="space-between" mt={6}>
      <Box>
        <BreadText bold> Role </BreadText>
        <BreadText>
          Designer
        </BreadText>
      </Box>

      <Box>
        <BreadText bold> Team </BreadText>
        <BreadText>
          Me
        </BreadText>
      </Box>

      <Box>
        <BreadText bold> Tools & Keywords </BreadText>
        <BreadText>
        Figma <br/>
        Adobe Illustrator <br/>
        React.js, Styled Components
        </BreadText>
      </Box>

      <Box>
        <BreadText bold> Duration </BreadText>
        <BreadText>
          1 semester
        </BreadText>
      </Box>

    </Box>
  );
}

function Overview() {
  return (
    <DefaultBox>
      <MetaTitle color={SIGNAL_COLOR}> OVERVIEW </MetaTitle>
      <SectionTitle> Machu Picchu Catering</SectionTitle>
      <BreadText>
        Machu Picchu Catering is a fictive catering website I made for my mom,
        during a NTNU course called GUI (User Interface Design). The website is a single page application.
        <br/>
        In the course GUI we were asked to make a website using and corresponding style guide,
        for a fictive website of our choice. <br/>
        With my mother being from Peru, and a chef
        I decided to make a catering website for her food. <br/>
        Later, I decided to develop it as well.
      </BreadText>
      <Box display={'flex'} mt={3} justifyContent={'flex-start'}>

      <CustomButton
            theme={theme}
            variant="outlined"
            target="_blank"
            href="https://www.mpcatering.no"
            >
            Visit the website
          </CustomButton>
      </Box>

      <MetaInfo />
    </DefaultBox>
  );
}


const MPContent = () => {
  return (
    <Box

    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    gap={8}
    >


      <Overview />
      <SiteMap />
      <ColorTheme />
      <Logo />

    </Box>
  )
}

export default MPContent