import * as React from 'react'
import NavBar from '../components/Navbar'
import Box from '@mui/material/Box'
import {
 GAP_VALUE, DefaultPage, Title, Header, BreadText, TwoContainer, HalfContainer,BackgroundBox, ScrollBarDiv
} from '../styled/Utils'
import PROFILE from './images/about/Profile.jpeg'
import styled from 'styled-components'


const PARK_GREEN = '#00FF00'


const SubTitle = styled.p`
  font-weight:100 ;
`;

const Info = () => {

    return(

        <TwoContainer>
            <HalfContainer>
                <Header> Education </Header> 
                <BreadText> 
                <SubTitle> Year course in Acting</SubTitle> 
                Romerike FHS | 2017-2018
                <br/><br/>
                
                <SubTitle> Bachelor in Informatics </SubTitle>
                NTNU Trondheim | 2018 - 2021
                <br/><br/>
                <SubTitle> Integrated Masters in Industrial design  </SubTitle>
                NTNU Trondheim | 2020-2024 
                </BreadText>

                <Header> Tools </Header> 
                <BreadText> 
                Figma
                <br/> Adobe CC - AI, Ps, Lr, Id
                <br/> HTML, CSS & JS
                <br/> P5.js
                <br/> TS & React
                <br/> Python
                <br/> Java
                <br/> GraphQL
                <br/> Fusion 360 & Keyshot
                <br/> Arduino & RPI
                <br/> FCP X
                </BreadText>


            </HalfContainer>

            <HalfContainer>

                
            <Header> Experience </Header>
                <BreadText>

                <SubTitle> SIKT </SubTitle>
                Designer - Summer Intern
                <br/>
                Summer 2024
                <br/><br/>

                <SubTitle> Lovdata </SubTitle>
                Designer - Summer Intern
                <br/>
                Summer 2023
                <br/><br/>


                <SubTitle> DesignEXPO </SubTitle>
                Leader
                <br/>
                August 2022 - February 2023
                <br/><br/>

                <SubTitle> Strise </SubTitle>
                Web Developer and Designer<br/>
                June 2022 - August 2022
                <br/><br/>
                <SubTitle> Schibsted - Tech Experiments</SubTitle>
                Developer and prototyper<br/>
                June 2021 - August 2021
                <br/><br/>
                <SubTitle> Strindens Promenade Orchester </SubTitle>
                Leader<br/>
                January 2020 - January 2021
                </BreadText>


                <Header> Skillset </Header>
                <BreadText>
                Interaction design
                <br/> Visual communication design
                <br/> CAD modeling
                <br/> Front-end web dev + design
                <br/> Back-end web dev
                <br/> Hardware + software prototyping
                <br/> Photography and filming
                <br/> Public Speaking

                </BreadText>
            </HalfContainer>


        </TwoContainer>
    )
}

const HoverGif = () => {
  return (
    <Box position={'relative'}>
    <Box position={'absolute'} zIndex={10}>
      <img src={PROFILE} alt='Profile photo' width={'500px'} height={'340px'} style={{borderRadius: 2}} />
    </Box>

    </Box>
  )
}

const Index = () => {
  return (
    <ScrollBarDiv>

      <BackgroundBox>

      <NavBar />
      <Box
          display='flex'
          justifyContent='center'
          paddingBottom={10}
      >
        <DefaultPage>

          <Box mb={6}>
            <Title> About </Title>
          </Box>
          <Box mt={6} mb={12}>
          <TwoContainer>
            <HalfContainer >
              <Box display={'flex'} flexDirection={'column'} gap={GAP_VALUE}>
              <BreadText>
                HI! My name is Mikkel Angelo Anchissi joner, I'm an interdisciplinary designer with background in UX design, product design and computer science. I’m inspired by stories and love telling them and hearing them!
                <br />            <br />
                I hold a bacehlors degree in informatics from NTNU and i'm currently completing my second degree which is an integrated master (5 years) industrial design at NTNU.
                <br />            <br />
                In my sparetime I like to play different instruments and do theater. I also love taking analog photographs and filming.
              </BreadText>
              </Box>
            </HalfContainer>
            <HalfContainer>
              <HoverGif />
            </HalfContainer>
          </TwoContainer>
          </Box>
         <Info />
        </DefaultPage>

      </Box>
      </BackgroundBox>

    </ScrollBarDiv>
  )
}

export default Index
