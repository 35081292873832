import React, {useEffect} from 'react'
import {
  IndexBox, 
  BackgroundBox,
  ScrollBarDiv
} from '../../styled/Utils'
import LovdataContent from './LovdataContent'
import NavBar from '../../components/Navbar'

export default function Lovdata () {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <ScrollBarDiv>

    <BackgroundBox>
      <NavBar />
      <IndexBox>

      
        <LovdataContent />

      </IndexBox>
    </BackgroundBox>
  </ScrollBarDiv>
  )
}
