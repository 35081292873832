import * as React from 'react'
import NavBar from '../../components/Navbar'
import {
   BreadText, ScrollBarDiv, IndexBox,GlobalStyle, BackgroundBox,SectionTitle, MetaTitle, DefaultBox
} from '../../styled/Utils'
import { Box } from '@mui/system'
import Cover from '../images/projects/Speakers/Cover.jpg'
import Blue from '../images/projects/Speakers/Blue.jpg'
import PinkStrong from '../images/projects/Speakers/PinkStrong.jpg'
import PinkWavy from '../images/projects/Speakers/PinkWavy.jpg'

const Index = () => {
  return (
    <ScrollBarDiv>
    <BackgroundBox>
      <GlobalStyle/>
      <NavBar />
      <IndexBox>
        
      <DefaultBox>
      <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={8} //! Se på denne
    >
      <Box
        component="img"
        maxWidth="100%"
        alt="Speaker Cover Photo"
        src={Cover}

      />


      <Box width={'100%'}> 

  
      <MetaTitle>Speaker design based on 80s aesthetic</MetaTitle>
      <SectionTitle> The Pocket </SectionTitle>
      <BreadText>
      This was my first product design project. 
      The task was to create a speaker while learning fundamental product design techniques.
      <br/> I wanted to make a speaker you could carry in a cigarette pocket.
      </BreadText>

      </Box>
      <Box
        component="img"
        maxWidth="100%"
        alt="Speaker Cover Photo"
        src={Blue}

      />
        <Box
        component="img"
        maxWidth="100%"
        alt="Speaker Cover Photo"
        src={PinkStrong}

      />
        <Box
        component="img"
        maxWidth="100%"
        alt="Speaker Cover Photo"
        src={PinkWavy}

      />

      </Box>



      </DefaultBox>

      </IndexBox>
    </BackgroundBox>
</ScrollBarDiv>

  )
}

export default Index
