import * as React from 'react'
import NavBar from '../../components/Navbar'
import Grid from '@mui/system/Unstable_Grid/Grid'
import {
   BreadText, CustomButton, ScrollBarDiv,  MetaTitle, SectionTitle,GlobalStyle, BackgroundBox, WorkSummaryTitle, Tags, DefaultBox
} from '../../styled/Utils'
import { createTheme } from '@mui/material/styles';
import styled from 'styled-components'
import { Box } from '@mui/system'
import LD01 from '../images/LDPRO/01.jpg'
import LD02 from '../images/LDPRO/02.jpg'
import LD03 from '../images/LDPRO/03.jpg'
import LD04 from '../images/LDPRO/04.jpg'
import LD05 from '../images/LDPRO/05.jpg'
import LD06 from '../images/LDPRO/06.jpg'
import LD07 from '../images/LDPRO/07.jpg'
import LD08 from '../images/LDPRO/08.jpg'
import LD09 from '../images/LDPRO/09.jpg'
import LD10 from '../images/LDPRO/10.jpg'
import LD11 from '../images/LDPRO/11.jpg'
import LD12 from '../images/LDPRO/12.jpg'
import LD13 from '../images/LDPRO/13.jpg'
import LD14 from '../images/LDPRO/14.jpg'
import LD15 from '../images/LDPRO/15.jpg'
import LD16 from '../images/LDPRO/16.jpg'
import LD17 from '../images/LDPRO/17.jpg'
import LD18 from '../images/LDPRO/18.jpg'
import LD19 from '../images/LDPRO/19.jpg'
import LD20 from '../images/LDPRO/20.jpg'
import LD21 from '../images/LDPRO/21.jpg'
import LD22 from '../images/LDPRO/22.jpg'
import LD23 from '../images/LDPRO/23.jpg'
import LD24 from '../images/LDPRO/24.jpg'
import LD25 from '../images/LDPRO/25.jpg'
import LD26 from '../images/LDPRO/26.jpg'

const SIGNAL_COLOR = '#4A0D17';
const HIGHLIGHT_COLOR = '#FEFEFE';

const theme = createTheme({
  main: {
    palette: {
      primary: SIGNAL_COLOR,
      secondary: HIGHLIGHT_COLOR,
    },
  },
});

const LovdataPictures = [LD02,LD03, LD04, LD05, LD06, 
  LD07, LD08, LD09, LD10, LD11, LD12, LD13, LD14, LD15, LD16,
  LD17, LD18, LD19, LD20, LD21, LD22, LD23, LD24, LD25, LD26]



const LovdataImages = styled(Box)`
    height:80vh;
 

    //object-fit:contain;

    & > img{
      height: inherit;

    }

    @media (max-width: 768px) {
    height: inherit;
    }
`;

const MyRole = () => {
  return (
    <DefaultBox>
      <SectionTitle>
        My Role
      </SectionTitle>
      <BreadText>
      The team consisted of 7 informatics students. We all coded, 
      but since I had an interest and background as a designer I took on that responsibility as well. 
      I participated and conducted user interviews and stakeholder interviews as 
      well as creating new design for some of the pages.
      </BreadText>
    </DefaultBox>
  )
}


function Overview() {
  return (
    <DefaultBox paddingBottom="5vh">
      <MetaTitle > OVERVIEW </MetaTitle>
      <SectionTitle> Designing a visually congruent tool for law students</SectionTitle>
      <BreadText>

        In the spring of 2023 I had a course where I had to find an external partner or company to design for.
        After talking with a friend who studies law in Norway he said that the tool that they are obliged to use is not that well designed.
        He inclined me to look into it and contact them, and so I did.
        <br />
        <br />
        This summer project landed me a summer internship where I designed a design system in Figma for Lovdata.
        As well as redesigning some internal pages.
      </BreadText>


    </DefaultBox>
  );
}

const Index = () => {
  return (
    <ScrollBarDiv>
    <BackgroundBox>
      <GlobalStyle/>  
      <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: '100vh' }}
        >
          <LovdataImages
          component="img"

          alt="Schibsted Cover Photo"
          src={LD01}
          paddingBottom="5vh"
          />
            <Overview />
            <CustomButton
            theme={theme}
            variant="outlined"
            target="_blank"
            href="https://www.figma.com/proto/yGmnVuG20Lx9oBq47SQvoj/Lovdata-D6?page-id=237%3A2&node-id=238-5&starting-point-node-id=238%3A5&scaling=scale-down-width&content-scaling=fixed&t=gqcVtvVKUchSysVS-1"
            >
            Visit the prototype
          </CustomButton>


          {LovdataPictures.map((picture,index) => {
            return(
            <LovdataImages 
            key={index}
            component='img'
            src={picture}
            paddingTop="3vw"
            />
            )
          })}
    </Grid>

    </BackgroundBox>
</ScrollBarDiv>

  )
}

export default Index
