import React, {useEffect} from 'react'
import Box from '@mui/material/Box'
import {
  DefaultBox,
  IndexBox,
  BackgroundBox,
  GlobalStyle,
  ScrollBarDiv
} from '../../styled/Utils'
import PepppsyContent from './PepppsyContent'
import NavBar from '../../components/Navbar'


const Iframe = ({src}) =>{


      return(         
        <Box >          
          <iframe src={src} width={1080} height={610} style={{"frameborder":"0"}}/>         
        </Box>
      )
    }



export default function Pepppsy () {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <ScrollBarDiv>

    <BackgroundBox>
      <GlobalStyle/>
      <NavBar />
      <IndexBox>
        <DefaultBox>
            <Box width={'100%'} display={'flex'} justifyContent={'center'}>
            <Iframe src="https://player.vimeo.com/video/749530789?h=e63a70c25a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" />
            </Box>
          <PepppsyContent />
        </DefaultBox>
      </IndexBox>
    </BackgroundBox>
    </ScrollBarDiv>
  )
}
