import React from 'react'
import styled from 'styled-components'
import Abanik from '../images/projects/thumb/Abanik.jpg'
import Lysbryter from '../images/projects/thumb/Lysbryter.jpg'
import Knagg from '../images/projects/thumb/Knagg.jpg'
import Lysistrata from '../images/projects/thumb/Lysistrata.jpg'
import Regret from '../images/projects/thumb/VisCom.jpg'
import Speakers from '../images/projects/thumb/Speaker.jpg'
import { Link } from 'react-router-dom'
import { Box, Container } from '@mui/system'
import {
    MetaTitle, SectionTitle, BreadText,
 } from '../../styled/Utils'

const ProjectList = [
    {
        title: 'Abanik',
        img: Abanik,
        desc: 'A room divider for the KAMD project at NTNU',
        keywords: ['Product'],
        tools: ['Rhyno'],
        url: 'Abanik',
        spanRow: 2
    },
    {
        title: 'The Pocket',
        img: Speakers,
        desc: 'Retro Speaker Design based on 80s aesthetic',
        keywords: ['Product Design'],
        tools: ['AI'],
        url: 'Speakers',
        spanCol: 2
    },
    {
        title: 'Sub',
        img: Knagg,
        desc: 'A childrens clothes hook',
        keywords: ['Product Design'],
        tools: ['AI'],
        url: 'Knagg',

    },
    {
        title: 'Lumos',
        img: Lysbryter,
        desc: 'A light switch for injection molded plastic',
        keywords: ['Product Design'],
        tools: ['AI'],
        url: 'Light',

    },

    {
        title: 'Lysistrata',
        img: Lysistrata,
        desc: 'Sweater design for a theatre production',
        keywords: ['Visual Design'],
        tools: ['Photoshop'],
        url: 'Lysistrata'
    },
    {
        title: 'Lines, circles and pixels',
        img: Regret,
        desc: '12 Artwork based on poems by David Whyte',
        keywords: ['Visual Design', 'Illustration', 'Generative Design'],
        tools: ['Photoshop', 'P5.js','Indesign'],
        url: 'LCP'
    },
   
]
const CellTitle = styled(SectionTitle)`
    margin-bottom:2px;
    color: #fefefe;
`;

const CellDesc = styled(MetaTitle)`
    text-align: center;
`;

const GridContainer = styled.div`
    overflow:hidden ;
    display:grid;
    grid-template-columns: repeat(3,1fr);;
    grid-gap:1rem;
    grid-auto-flow: dense;
    justify-content: space-around;
    @media (max-width: 768px) {
        grid-template-columns:80vw;
        grid-auto-rows:80vw;
  }
`;


const GalleryItem = styled.div`

    position:relative;
    grid-row: span ${props => props.spanRow};
    grid-column: span ${props => props.spanCol};
    aspect-ratio:${props => props.spanCol/props.spanRow};
    transition: 0.7s;
    &:hover{
        background-color:black;
    }

    @media (max-width: 768px) {
        grid-row: span 1;
        grid-column: span 1;
  }
`;



const GalleryLink = styled(Link)`
 text-decoration: none;
 color: inherit;
`;



const GalleryImage = styled.div`
    width:100%;
    height:100%;
    overflow:hidden;
    opacity:1 ;
    transition: 0.7s;
    & >img {
    width:100%;
    height:100%;
    object-fit: cover;
    object-position:50% 50%;
    }
    ${GalleryItem}:hover & {
        opacity: 0.7;
  }
`;

const GalleryItemText = styled.div`

    display: flex;
    flex-direction:column ;
    justify-content:center;
    align-items:center;
    height: 100%;
    width:100%;
    opacity:0;
    position:absolute;
    z-index: 100;
    transition: .5s ease-in-out;
    ${GalleryItem}:hover & {
        opacity:1;
  }
`;


function GridItem({img,title,text, url ,spanRow,spanCol}){

    let sendRow = spanRow ? spanRow : 1
    let sendCol = spanCol ? spanCol : 1

    return(
        <GalleryItem spanRow={sendRow} spanCol={sendCol}>

            <GalleryLink to={url}>
    
                <GalleryItemText> 
                    <CellTitle> {title} </CellTitle> 
                    <Box width={'70%'}>

                    <CellDesc color={'#fefefe'}> {text} </CellDesc>
                    </Box>
                        
                </GalleryItemText>
            <GalleryImage>
                <Box 
                    component='img'
                    src={img}

                    />
            </GalleryImage>
            </GalleryLink>

        </GalleryItem>
    )
}

function Projects(){

    return(
      <Container>
       <GridContainer>
            {
                ProjectList.map((project, index)=>     (
                    <GridItem key={index} img={project.img} title={project.title} text={project.desc} url={project.url} spanRow={project.spanRow} spanCol={project.spanCol}/>

                ))
            }


       </GridContainer>
      </Container>
    )
}

export default Projects