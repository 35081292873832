import React, {useState, useEffect} from "react";
import {
  IndexBox,
  DefaultBox,
  HomePageText,
  ScrollBarDiv,
  TextAndMedia, 
  MediaBox
} from '../../styled/Utils'
import { Box } from "@mui/system";
import VINK  from '../images/LandingPage/Vink.gif'
import { CardMedia, Card } from "@mui/material";




export default function HomePage(){
  return(



        

      <ScrollBarDiv>

      <IndexBox>
        <DefaultBox>


      <TextAndMedia>

 



      <MediaBox width={1} overflow={'hidden'} borderRadius={'6px'} order={-1}>
        <CardMedia 
        src={VINK}
        component='img'
        sx={{width: '50%'}}
        />
        </MediaBox>


      <Box width={1}>
        <HomePageText>Hi! I'm Mikkel Angelo <br/>
        - an interdisciplinary designer with background in UX design
           product design and computer science.
           I'm inspired by stories and love
           telling them and hearing them!
        </HomePageText>
        </Box>

        </TextAndMedia>
        </DefaultBox>

        </IndexBox>

        </ScrollBarDiv>

  )
}