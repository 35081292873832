import * as React from 'react'
import NavBar from '../../components/Navbar'
import {
   BreadText, ScrollBarDiv, IndexBox,GlobalStyle, BackgroundBox, WorkSummaryTitle, MetaTitle, Tags, DefaultBox
} from '../../styled/Utils'
import { Box } from '@mui/system'
import Abanik1 from '../images/projects/Abanik/Abanik_Side_1.jpg'
import Abanik2 from '../images/projects/Abanik/Abanik_Side_2.jpg'
import Abanik3 from '../images/projects/Abanik/Abanik_Side_3.jpg'
import Abanik4 from '../images/projects/Abanik/Abanik_Side_4.jpg'
import Abanik5 from '../images/projects/Abanik/Abanik_Side_5.jpg'
import Abanik6 from '../images/projects/Abanik/Abanik_Side_6.jpg'

const AbanikPictures = [Abanik1, Abanik2, Abanik3, Abanik4, Abanik5, Abanik6]

import styled from 'styled-components'

const AbanicImages = styled(Box)`
    height:100vh;
    object-fit:contain;

    & > img{
      height: inherit;
    }

    @media (max-width: 768px) {
    height: inherit;
    }
`;

const Index = () => {
  return (
    <ScrollBarDiv>
    <BackgroundBox>
      <GlobalStyle/>
      <NavBar />
      <IndexBox>
        
      <DefaultBox flexWrap={'noWrap'}>


   
          {AbanikPictures.map((picture,index) => {
            return(
            <AbanicImages 
            key={index}
            component='img'
            src={picture}
            />
            )
          })}



</DefaultBox>

      </IndexBox>
    </BackgroundBox>
</ScrollBarDiv>

  )
}

export default Index
